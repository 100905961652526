import '../css/three.css';

const Three = () => {
    return ( 
        <div id="section-three" className="section-mrt-blocks anchored">
            <div id="section-three-anchor" className="anchor"></div>
            <div className="container boxed">
                <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <h1>POWDER COAT <span className="cust-text-header">PACKAGES</span></h1>
                    </div>
                </div>
                <div className="mrt-row">
                    <div className="mrt-col col-60">
                        <div className="list-holder">
                            <div className="mrt-row row-reversed">
                                <div className="mrt-col col-50">
                                    <span className="powdercoated-text">Powder coated finish</span>
                                    <ul className="list">
                                        <span>TRAY</span>
                                        <li className="item li-head">1650 or 1800x1800 Aluminium Flat-bed Ute Tray</li>
                                        <li className="item">Powder Coat Finish </li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours</a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours</a></li>
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Under Tray Toolboxes</li>
                                        <li className="item">Flared Mudguards</li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind Spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model </li>
                                    </ul>
                                    <ul className="list">
                                        <span>CANOPY</span>
                                        <li className="item li-head">1650 or 1800mm Long x 1800mm wide x 860mm high Aluminium Ute Canopy </li>
                                        <li className="item">Powder Coat Finish </li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours</a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours</a></li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Keyless Entry System</li>
                                        <li className="item">All installation and fitting charges</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$10,964</s></span></p>
                                    <p className="current-price">$9,999</p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/initiator-package-powdercoated-finish-order-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                                <div className="mrt-col col-50">
                                    <span className="powdercoated-text">Powder coated finish WITH LIFT-OFF</span>
                                    <ul className="list">
                                        <span>TRAY</span>
                                        <li className="item li-head">1650 or 1800x1800 Aluminium Flat-bed Ute Tray</li>
                                        <li className="item">Powder Coat Finish </li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours</a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours</a></li>  
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Under Tray Toolboxes</li>
                                        <li className="item">Flared Mudguards</li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind Spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model </li>
                                    </ul>
                                    <ul className="list">
                                        <span>CANOPY</span>
                                        <li className="item li-head">1650 or 1800mm Long x 1800mm wide x 860mm high Aluminium Ute Canopy </li>
                                        <li className="item">Powder Coat Finish </li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours</a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours</a></li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Keyless Entry System</li>
                                        <li className="item">Lift-off System</li>
                                        <li className="item">All installation and fitting charges</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$12,581</s></span></p>
                                    <p className="current-price">$10,999 </p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/initiator-package-powdercoated-finish-with-lift-off-order-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-40">
                        <div className="img-holder">
                            <video playsInline autoPlay loop muted id="powder-coat-video">
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/05/powdercoat-initiator-1.mp4" type="video/mp4" />
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/05/powdercoat-new-1.webm" type="video/webm" />
                                <source src="undefined" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Three;
