// import useFetch from '../../useFetch';
import One from '../sections/One.jsx';
import Banner from '../sections/Banner.jsx';
import Two from '../sections/two';
import Three from '../sections/three';
import Four from '../sections/Four.jsx';
import main_img from '../media/Initiator-package-copy-1.png'
import icon_canopy from '../svgs/Canopy-1.svg';
import icon_keyless_entry from '../svgs/Keyless-Entry-3.svg';
import icon_flared_mudguards from '../svgs/mudguards-1.svg';
import icon_roof_racks from '../svgs/Roof-racks-1.svg';
import icon_led_lights from '../svgs/Tail-light-1.svg';
import icon_toolboxes from '../svgs/Tool-boxes-1.svg';
import icon_tray from '../svgs/Tray-1.svg';
import icon_wrench from '../svgs/wrench-white-1.svg';
import title_img from "../media/tradesman-banner-stroke.png";
import SecNav from '../sections/sec-nav';
import green_arrow from "../media/green-arrow.png";	 
import Header from '../sections/header';

const Packages = () => {
	// const {data: posts, ispending, error} = useFetch('http://127.0.0.1:8080/my-headless-app/server/wp-json/wp/v2/posts');

	const data = [
		{
			"icon": icon_tray,
			"title": "FLATBED UTE TRAY",
			"link": "#ute-tray"
		},
		{
			"icon": icon_flared_mudguards,
			"title": "Flared Mudguards",
			"link": "#flared-mudguards"
		},
		{
			"icon": icon_led_lights,
			"title": "LED Tail lights",
			"link": "#led-lights"
		},
		{
			"icon": icon_toolboxes,
			"title": "Under Tray Toolboxes",
			"link": "#undertray-toolboxes"
		},
		{
			"icon": icon_canopy,
			"title": "3-door Canopy",
			"link": "#3-door-canopy"
		},
		{
			"icon": icon_roof_racks,
			"title": "3 X Ladder Racks",
			"link": "#roof-racks"
		},
		{
			"icon": icon_keyless_entry,
			"title": "Keyless Entry system",
			"link": "#keyless-system"
		},
		{
			"icon": icon_wrench,
			"title": "ALL INSTALLATION + FITTING",
			"link": "#fitting-installation"
		}
	
	]

	const props_one = {
		"main_img": main_img,
		"data": data,
		"green_arrow": green_arrow,
	}



	return (
		<>
		<Header title="Home" />
		<div id="main" className="site-main page__top-shadow visible">
			<div id="main-content" className="main-content">
				<Banner title_img={title_img}></Banner>
				<SecNav />			
				<One props={props_one}></One>
				<Two />
				<Three />
				<Four icon_roof_racks={icon_roof_racks} green_arrow={green_arrow} />
			</div>
		</div>
		</>
	);
}

export default Packages;

