import Header from "../sections/header";
import {useParams} from 'react-router-dom';
import useFetch from "../../useFetch";

const Post = () => {
    const {id} = useParams();
    
    const {data: post, error, isLoading} = useFetch('http://127.0.0.1:8080/my-headless-app/server/wp-json/wp/v2/posts/'+id);

    return (
        <Header title={post && post.title.rendered}>
            <div className="post">
                {error && <div>{error}</div>}
                {isLoading && <div>Loading...</div>}
                {post && (
                    <article>
                        <h2>{post.title.rendered}</h2>
                        <div dangerouslySetInnerHTML={{__html: post.content.rendered}}></div>
                    </article>
                )}
            </div>
        </Header>
    );
}
export default Post;