import React from "react";
import "../../css/ute-canopy/UtecanopySizes.css";
import '../../css/ute-canopy/Utecanopy.css'
import SizeWidth from "../../media/UteCanopy/sizes-width-1-1.png";
import SizeLength from "../../media/UteCanopy/sizes-length-1-1.png";
import SizeHeight from "../../media/UteCanopy/sizes-height-1.png";
const UtecanopySizes = () => {
  return (
    <>
      <div id="sizes" className="section-mrt-blocks anchored">
        <div id="sizes-anchor" className="anchor"></div>
        <div className="container fullwidth">
          <div className="mrt-row">
            <div className="mrt-col col-100">
              <div className="title-holder">
                <h2>SIZES</h2>
              </div>
            </div>
          </div>
          <div className="mrt-row gradient-bg-rev">
            <div className="mrt-col col-30">
              <div className="info-holder bg-clear">
                <div className="image-holder">
                  {/* <img
                    src="https://www.mrt.com.au/wp-content/uploads/2022/09/sizes-width-1-1.png"
                    alt="MRT width options ute canopy"
                  /> */}
                    <img
                    src={SizeWidth}
                    alt="MRT width options ute canopy"
                  />
                </div>
                <p>
                  <b>Standard Widths</b>
                  <br />■ 1800mm
                  <br />■ 1900mm
                  <br />■ Custom sizes available
                  <br />
                  &nbsp;&nbsp;&nbsp;-{" "}
                  <a
                    href="https://www.mrt.com.au/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>{" "}
                  for more information
                </p>
              </div>
            </div>
            <div className="mrt-col col-40">
              <div className="info-holder bg-clear">
                <div className="image-holder">
                  {/* <img
                    src="https://www.mrt.com.au/wp-content/uploads/2022/09/sizes-length-1-1.png"
                    alt="MRT length options ute canopy"
                  /> */}
                  <img
                    src={SizeLength}
                    alt="MRT length options ute canopy"
                  />                  
                </div>
                <p>
                  <b>Standard Lengths</b>
                  <br />
                  <span style={{ display: "inline-block", width: "150px" }}>
                    ■ 1200mm
                  </span>
                  ■ 2100mm
                  <br />
                  <span style={{ display: "inline-block", width: "150px" }}>
                    ■ 1650mm
                  </span>
                  ■ 2400mm
                  <br />■ 1800mm
                  <br />■ Custom sizes available
                  <br />
                  &nbsp;&nbsp;&nbsp;-{" "}
                  <a
                    href="https://www.mrt.com.au/contact/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    contact us
                  </a>{" "}
                  for more information
                </p>
              </div>
            </div>
            <div className="mrt-col col-30">
              <div className="info-holder bg-clear">
                <div className="image-holder">
                  {/* <img
                    src="https://www.mrt.com.au/wp-content/uploads/2022/09/sizes-height-1.png"
                    alt="MRT height options ute canopy"
                  /> */}
                  <img
                    src={SizeHeight}
                    alt="MRT height options ute canopy"
                  />
                </div>
                <p>
                  <b>Heights</b>
                  <br />■ 860mm
                  <br />■ 1000mm
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UtecanopySizes;
