import React from 'react'
import '../css/SecNav.css'
import { useEffect, useRef } from 'react';


const SecNav = () => {
    const divRef = useRef(null);

    useEffect(() => {
      const div = divRef.current;
        // Debounce function
        const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
            func.apply(null, args);
            }, delay);
        };
        };
      const handleScroll = () => {
        const scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolledPercentage = (scrollTop / scrollHeight) * 100;
  
        // Replace 50 with the desired scroll percentage
        if (scrolledPercentage >= 36) {
          div.classList.add('sticky');
        } else {
          div.classList.remove('sticky');
        }
      };
      const debouncedHandleScroll = debounce(handleScroll, 100);
      
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
        <>
            <div id="sec-nav" ref={divRef}>
                <div className="container">
                    <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <div className="menu-list-holder">
                        <ul className="menu-list">
                            {/* <li className="menu-item build">
                            <a href="#section-canopy-builder-anchor">
                                BUILD YOUR OWN <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li> */}
                            <li className="menu-item">
                            <a href="#features-anchor">
                                FEATURES <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li>
                            <li className="menu-item">
                            <a href="#sizes-anchor">
                                SIZES <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li>
                            <li className="menu-item">
                            <a href="#options-anchor">
                                OPTIONS <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li>
                            <li className="menu-item">
                            <a href="#accessories-anchor">
                                ACCESSORIES <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li>
                            <li className="menu-item">
                            <a href="#gallery-anchor">
                                GALLERY <i className="fa-light fa-arrow-down" aria-hidden="true"></i>
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecNav;