import React from "react";
import { useLocation } from "react-router-dom";

import FooterSearch from "./FooterSearch";
import "../css/nextsteps.css";
import Perth from "../media/nextSteps/LocationmapPerth.png";
import Brisbane from "../media/nextSteps/LocationmapBrisbane.png";
import Sydney from "../media/nextSteps/LocationmapSydney.png";
import telephone from "../media/nextSteps/telephone.png";
import mail from "../media/nextSteps/mail.png";
import landmark from "../media/nextSteps/location.png";

const NextSteps = () => {
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <>
      <div id="section-next-steps">
      <div className={`container ${isHome ? "hidden" : ""}`}>
          <div className="row steps-contact">
            <div className="col col-sm-12">
              <div className="info-holder">
                <h3 className="mrt-title">
                  WANT TO KNOW MORE? CONTACT US TODAY
                </h3>
                <p className="step-desc">
                  Our friendly and experienced team of professionals are ready
                  and waiting to answer any further questions you have in regard
                  to MRT products and services. Call us on{" "}
                  <a href="tel:1300650090">1300 650 090</a> today. We encourage
                  all to visit one of our{" "}
                  <a href="https://www.mrt.com.au/locations/brisbane-ute-canopies-and-ute-trays/">
                    Brisbane
                  </a>
                  ,
                  <a href="https://www.mrt.com.au/locations/sydney-ute-canopies-and-ute-trays/">
                    Sydney
                  </a>{" "}
                  or{" "}
                  <a href="https://www.mrt.com.au/locations/perth-ute-canopies-and-ute-trays/">
                    Perth
                  </a>
                  showrooms and check out our latest products and team updates
                  on{" "}
                  <a href="https://www.facebook.com/mrtaustralia/">Facebook</a>{" "}
                  and{" "}
                  <a href="https://www.instagram.com/mrt_australia/">
                    Instagram
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-locations" className="section margin-top anchored">
        <div id="section-locations" className="anchor"></div>
        <div className="container wider">
          <div className="row">
            <div className="col col-33">
              <div className="content-holder">
                <div className="image">
                  <img
                    src={Brisbane}
                    alt=""
                  />
                </div>
                <h3>BRISBANE & GOLD COAST STORE</h3>
                <a
                  href="https://maps.app.goo.gl/fc5hsknPxy6TQ7XHA"
                  target="_blank"
                >
                  <span>
                    <img
                      src={landmark}
                      alt=""
                    />
                    VIEW ON GOOGLE MAPS
                  </span>
                </a>
                <p>
                  <span>14 Dixon Street, Yatala, QLD 4207, Australia</span>
                  <br />
                  <span>
                    Phone: <a href="tel:0734622300">07 34622300</a>
                  </span>
                </p>
              </div>
            </div>

            <div className="col col-33">
              <div className="content-holder">
                <div className="image">
                  <img
                    src={Perth}
                    alt=""
                  />
                </div>
                <h3>PERTH STORE</h3>
                <a
                  href="https://maps.app.goo.gl/fVC95XNdfmRC1w6VA"
                  target="_blank"
                >
                  <span>
                    <img
                      src={landmark}
                      alt=""
                    />
                    VIEW ON GOOGLE MAPS
                  </span>
                </a>
                <p>
                  <span>
                    29 Discovery Dr, Bibra Lake, Western Australia 6163,
                    Australia
                  </span>
                  <br />
                  <span>
                    Phone: <a href="tel:0861462543">08 61462543</a>
                  </span>
                </p>
              </div>
            </div>

            <div className="col col-33">
              <div className="content-holder">
                <div className="image">
                  <img
                    src={Sydney}
                    alt=""
                  />
                </div>
                <h3>SYDNEY STORE</h3>
                <a
                  href="https://maps.app.goo.gl/uvzFBo1Fv4suvCeYA"
                  target="_blank"
                >
                  <span>
                    <img
                      src={landmark}
                      alt=""
                    />
                    VIEW ON GOOGLE MAPS
                  </span>
                </a>
                <p>
                  <span>
                    1/27 Williamson Rd, Ingleburn, New South Wales 2565,
                    Australia
                  </span>
                  <br />
                  <span>
                    Phone: <a href="tel:0281035306">02 81035306</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="section-cta" className="section anchored">
        <div id="section-cta" className="anchor"></div>
        <div className="container boxed">
          <div className="row">
            <div className="col col-100">
              <div className="content-holder">
                <p>
                  Visit MRT's showrooms in Gold Coast, Brisbane, Sydney, or
                  Perth. See the latest products and keep up with team updates
                  on Facebook and Instagram. The knowledgeable and friendly team
                  is on standby to address any questions through email, instant
                  chat, or phone call.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-50">
              <div className="content-holder">
                <a href="tel:1300650090" className="contact">
                  <img
                    src={telephone}
                    alt=""
                  />
                  1300 650 090
                </a>
              </div>
            </div>
            <div className="col col-50">
              <div className="content-holder right">
                <a href="mailto:sales@mrt.com.au" className="contact">
                  <img
                    src={mail}
                    alt=""
                  />
                  sales@mrt.com.au
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSearch />
    </>
  );
};

export default NextSteps;
