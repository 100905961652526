import "../../css/landing/AluminiumUteToolboxes.css";
import MRTHolder from "../../media/da2cfefd1236d653926d6357ed451201.gif";

const AluminiumUteToolboxes = () => {
  return (
    <div id="section-toolboxes-1" className="section anchored">
      <div id="section-2-anchor" className="anchor"></div>

      <div className="container wider">
        <div className="row">
          <div className="col col-60">
            <div className="img-holder">
              <img
                loading="lazy"
                src={MRTHolder}
                alt="mrt ute canopy with canopy toolboxes, ladder and jerry can holder"
              />
            </div>
          </div>

          <div className="col col-40">
            <div className="content-holder">
              <h2 className="title">Aluminium Ute Toolboxes</h2>
              <p className="desc">
                MRT has over 45 different models of toolboxes available in 13 varying styles which include - standard underbody toolboxes, 4WD underbody toolboxes, full opening toolboxes, full opening toolboxes with drawers, generator toolboxes, gullwing toolboxes, rectangle toolboxes, square opening toolboxes, 3/4 opening toolboxes and 3/4 opening toolboxes with drawers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AluminiumUteToolboxes;
