import React from "react";
import "../css/categoryMenu.css";
import canopyIcons from "../media/categoryIcons/mrt-icon-ute-canopies-1.png";
import trayIcons from "../media/categoryIcons/mrt-icon-ute-trays-1.png";
import packageIcons from "../media/categoryIcons/mrt-icon-canopy-tray-packages-1.png";
import toolboxIcons from "../media/categoryIcons/mrt-icon-toolboxes-1.png";
import keylessIcons from "../media/categoryIcons/mrt-icon-kes-1.png";
import accessoriesIcons from "../media/categoryIcons/mrt-icon-accessories-1.png";

const CategoryMenu = () => {
    const scrollContainerStyle = {
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch', // For better scrolling performance on touch devices
        msOverflowStyle: 'none', // For Internet Explorer and Edge
        scrollbarWidth: 'none', // For Firefox
        '&::WebkitScrollbar': {
          display: 'none', // For Chrome, Safari, and Opera
        },
      };
  return (
    <>
    <div id="section-1" className="section anchored">
    <div id="section-1-anchor" className="anchor"></div>
        <div className="container wide">
            <div className="row">
                <div className="col col-100">
                    <div className="icons-holder">
                        
                        <span className="arrowleft mobile">❮</span>
                        <span className="arrowright mobile">❯</span>

                        <div className="icons" style={scrollContainerStyle}>

                        <a href="https://www.mrt.com.au/aluminium-ute-canopies/" className="icon">
                            <span className="img-holder">
                            <img src={canopyIcons} alt="mrt ute canopy icon"/>
                            </span>
                            <span className="name">UTE CANOPIES</span>
                        </a>

                        <a href="https://www.mrt.com.au/aluminium-ute-trays/" className="icon">
                            <span className="img-holder">
                            <img src={trayIcons} alt="mrt ute tray icon"/>
                            </span>
                            <span className="name">UTE TRAYS</span>
                        </a>
                        
                        <a href="https://www.mrt.com.au/new-initiator-package/" className="icon">
                            <span className="img-holder">
                            <img src={packageIcons} alt="mrt ute canopy + ute tray package icon"/>
                            </span>
                            <span className="name">CANOPY + TRAY PACKAGES</span>
                        </a>
                        
                        <a href="https://www.mrt.com.au/aluminium-toolboxes/" className="icon">
                            <span className="img-holder">
                            <img src={toolboxIcons} alt="mrt ute toolboxes icon"/>
                            </span>
                            <span className="name">TOOLBOXES</span>
                        </a>
                        
                        <a href="https://www.mrt.com.au/keyless-entry-range/" className="icon">
                            <span className="img-holder">
                            <img src={keylessIcons} alt="mrt keyless entry system icon"/>
                            </span>
                            <span className="name">KEYLESS ENTRY SYSTEM</span>
                        </a>
                        
                        <a href="https://www.mrt.com.au/accessories/" className="icon">
                            <span className="img-holder">
                            <img src={accessoriesIcons} alt="mrt ute accessories icon"/>
                            </span>
                            <span className="name">ACCESSORIES</span>
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default CategoryMenu;
