import "../../css/aluminium-toolboxes/ToolBoxesVideoLeft.css";
import PlayButton from "../../media/play-button.png";
import ThreeQuarterVideoCover from "../../media/Three-Quarter-video-cover.png";


const ToolBoxesThreeQuarter = () => {
    return ( 
        <div className="section-mrt-blocks video-left dark">
            <div id="three-quarter-opening-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="video-holder" style={{backgroundImage: `url(${ThreeQuarterVideoCover})`}}>
                            <div className="video">
                                <div className="video-wrapper">
                                    <a href="#video-three-quarter-opening-toolboxes" className="video-image" rel="wp-video-lightbox">
                                        <img src={PlayButton} alt="Play Button" />
                                    </a>
                                    <div id="video-three-quarter-opening-toolboxes" style={{display: 'none'}}>
                                        <iframe title="Three Quarter Opening Toolboxes Video" width="100%" height="315" src="https://www.youtube.com/embed/bZ_yhm_nlkg" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>						
                    </div>
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">THREE QUARTER OPENING TOOLBOXES</h3>
                            <p className="mrt-desc">MRT 3/4 opening ute toolboxes are strategically designed to increase your storage capacity while in transit or onsite, in a safe and affordable manner. All are fitted with heavy-duty, stainless steel T-locks, dust/water seals - and are made from high-quality and extremely durable marine grade aluminium checker plate.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/3-4-opening-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolBoxesThreeQuarter;
