import '../../css/adventures/AdventureGears.css'
import logo from '../../media/logo.png';
import useFetch from "../../../useFetch";

const AdventureGears = ({props}) => {
    const {data: gears_img, ispending: gears_img_ispending, error: gears_img_error} = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/media/'+props.image);
    var gearsimg = null;
    if (gears_img !== null){
        gearsimg = gears_img.source_url;
    }
    return (
        <div id="section-gears" className="anchored">
            <div id="section-gears-anchor" className="anchor"></div>
            <div className="container wider">
                <div className="row">
                    <div className="col col-33">
                        <div className="image-holder">
                            <img src={gearsimg} alt={props.image_alt_description} draggable="false"/>
                        </div>
                    </div>
                    <div className="col col-67">
                        <h2>RECOMMENDED <span className="hollow">GEAR</span></h2>
                        <div className="row">
                            <div className="col col-50">
                                <div className="img-holder">
                                    <img src={logo} alt="MRT logo"/>
                                </div>
                                <h3 className="title">{props.title}</h3>
                                <a href={props.full_build_link}>Read our full build here <i className="fa-regular fa-arrow-right-long" aria-hidden="true"></i></a>
                                {props.column_1_lists.map((list, index) => (
                                    <div key={index}>
                                        <h3 className="subtitle">{list.list_title}</h3>
                                        <ul>
                                            {list.list_items.map((item, index) => (
                                                <li key={index}>{item.list_item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="col col-50">
                                {props.column_2_lists.map((list, index) => (
                                    <div key={index}>
                                        <h3 className="subtitle">{list.list_title}</h3>
                                        <ul>
                                            {list.list_items.map((item, index) => (
                                                <li key={index}>{item.list_item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AdventureGears;