import "../../css/aluminium-toolboxes/ToolBoxesVideoLeft.css";
import PlayButton from "../../media/play-button.png";
import crossdeckVideoCover from "../../media/Crossdeck-video-cover.png";

const ToolBoxesCrossDeck = () => {
  return (
    <div className="section-mrt-blocks video-left dark">
      <div id="cross-deck-toolboxes" className="anchor-holder"></div>
      <div className="container fullwidth">
        <div className="mrt-row">
          <div className="mrt-col col-50">
            <div className="video-holder" style={{backgroundImage: `url(${crossdeckVideoCover})`}}>
              <div className="video">
                <div className="video-wrapper">
                  <a href="#video-cross-deck-toolboxes" className="video-image" rel="wp-video-lightbox">
                    <img src={PlayButton} alt="Play Button" />
                  </a>
                  <div id="video-cross-deck-toolboxes" style={{ display: "none" }}>
                    <iframe title="Cross Deck Toolboxes Video" width="100%" height="315" src="https://www.youtube.com/embed/HgOaiYI9eHY" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mrt-col col-50">
            <div className="info-holder">
              <p className="mrt-subtitle"></p>
              <h3 className="mrt-title">CROSS DECK TOOLBOXES</h3>
              <p className="mrt-desc">MRT cross deck ute toolboxes increase onsite productivity by allowing you mass amounts of storage that is easily accessible. They are fit with weather resistant rubber seals, strong T-locks - and made with fully seam welded, heavy-duty 2.5mm aluminium checker plate. This long-lasting, high-quality design has impressive standard inclusions which offer great value for money.</p>
              <div className="animation-holder">
                <a href="https://www.mrt.com.au/aluminium-toolboxes/crossdeck-toolboxes/">
                  <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolBoxesCrossDeck;
