import '../../css/accessories/AccessoriesCategory.css';
import React, { useState, useEffect } from 'react';
import loadingGif from '../../media/preloader.gif';

const AccessoriesCategory = ({accessoriesProducts }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [filteredProducts, setFilteredProducts] = useState([]);
	const productsPerPage = 9;
  const [currentCategory, setCurrentCategory] = useState("all");

  
	
  const filterProducts = (category) => {
    if (category === "all") {
      setFilteredProducts(accessoriesProducts);
    } else {
      const filtered = accessoriesProducts.filter((product) =>
        product.categories.some((cat) => cat.slug === category)
      );
      setFilteredProducts(filtered);
    }
    setCurrentPage(1);
    setCurrentCategory(category);
  };
	
	const indexOfLastProduct = currentPage * productsPerPage;
	const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
	const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  
	
	useEffect(() => {
	  if (accessoriesProducts.length > 0) {
		setLoading(false);
		setFilteredProducts(accessoriesProducts); 
	  }
	}, [accessoriesProducts]);
  

	if (loading) {
    return (
      <div class="preloader" style={{ padding: "5% 0", textAlign: "center"}}>
        <div className=" container" style ={{height:"200px"}}>
          <img style={{ width: "auto" }} src={loadingGif} alt="Loading..." />
        </div>
      </div>
    );
	  
	}
  
  
	return (
    <div id="section-default-content" className="">
      <div className="container">
        <div className="panel row" style={{ display: "block" }}>
          <div className="panel-center col-xs-12">
            <div id="general_product_page">
              <div className="vc_column_container vc_col-sm-9">
                <div className="vc_column-inner">
                  <div className="product-grid">
                    <div className="portfolio-top-panel">
                      <div className="portfolio-filters">
                        <a
                          href="#"
                          onClick={() => filterProducts("all")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "all" ? "active" : ""
                          }`}
                        >
                          <span className="light">All</span>
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            filterProducts("keyless-entry-systems")
                          }
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "keyless-entry-systems"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="light">Keyless Entry Systems</span>
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            filterProducts(
                              "x-series-adjustable-shelving-and-drawer-units"
                            )
                          }
                          className={`title-h6 portfolio-filter ${
                            currentCategory ===
                            "x-series-adjustable-shelving-and-drawer-units"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="light">
                            X-series Adjustable Shelving and Drawer Units
                          </span>
                        </a>
                        <a
                          href="#"
                          onClick={() =>
                            filterProducts("x-series-drawer-modules")
                          }
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "x-series-drawer-modules"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="light">X-Series Drawer Modules</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => filterProducts("fridge-slides")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "fridge-slides" ? "active" : ""
                          }`}
                        >
                          <span className="light">Fridge Slides</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => filterProducts("fitting-kits")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "fitting-kits" ? "active" : ""
                          }`}
                        >
                          <span className="light">Fitting Kits</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => filterProducts("flooring")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "flooring" ? "active" : ""
                          }`}
                        >
                          <span className="light">Flooring</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => filterProducts("dog-box-accessories")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "dog-box-accessories"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="light">Dog Box Accessories</span>
                        </a>
                        <a
                          href="#"
                          onClick={() => filterProducts("jerry-can-holders")}
                          className={`title-h6 portfolio-filter ${
                            currentCategory === "jerry-can-holders"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="light">Jerry Can Holders</span>
                        </a>
                      </div>
                    </div>

                    <div className="products row" style={{ display: "block" }}>
                      <div className="portfolio-set">
                        {currentProducts.map((product) => (
                          <div
                            key={product.id}
                            className="portfolio-item product"
                          >
                            <div className="wrap">
                              <div className="image">
                                <a href="#">
                                  <div className="image-inner">
                                    <picture>
                                      {product.images.length > 0 && (
                                        <img
                                          src={`${product.images[0].src}`}
                                          alt={product.images[0].alt}
                                        />
                                      )}
                                    </picture>
                                  </div>
                                </a>
                              </div>

                              <div className="caption">
                                <div className="product-info">
                                  <div className="title mrt-product-title">
                                    <p className="mrt-product-category">
                                      Accessories
                                    </p>
                                    <a href={product.permalink}>
                                      {product.name}
                                    </a>
                                  </div>
                                </div>

                                <div className="product-price">
                                  <span className="price">
                                    {product.price_html && (
                                      <span
                                        className="result_price"
                                        dangerouslySetInnerHTML={{
                                          __html: product.price_html,
                                        }}
                                      ></span>
                                    )}
                                  </span>
                                </div>
                                <div className="mycostumproduct">
                                  {product.dimension && (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: product.dimension,
                                      }}
                                    ></p>
                                  )}
                                  <a href={product.permalink}>
                                    <div className="hvr-ripple-out">
                                      VIEW PRODUCT
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* Pagination control */}
                    <div className="portfolio-navigator gem-pagination">
                      <a
                        href="#"
                        className="prev"
                        style={{
                          visibility: currentPage <= 1 ? "hidden" : "visible",
                        }}
                        onClick={() => setCurrentPage(currentPage - 1)}
                      >
                        <i className="default"></i>
                      </a>
                      {Array.from(
                        {
                          length: Math.ceil(
                            filteredProducts.length / productsPerPage
                          ),
                        },
                        (_, i) => (
                          <a
                            key={i + 1}
                            href="#"
                            data-page={i + 1}
                            className={currentPage === i + 1 ? "current" : ""}
                            onClick={() => setCurrentPage(i + 1)}
                          >
                            {i + 1}
                          </a>
                        )
                      )}
                      <a
                        href="#"
                        className="next"
                        style={{
                          visibility:
                            currentPage >=
                            Math.ceil(filteredProducts.length / productsPerPage)
                              ? "hidden"
                              : "visible",
                        }}
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        <i className="default"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccessoriesCategory;



