import { useState, useEffect } from 'react';


const useFetch = (url) => {
    const [data, setdata] = useState(null);
    const [ispending, setIspending] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(url, {}).then(res => {
            if (!res.ok) {
                throw Error('Could not fetch the data for that resource');
            }
            return res.json();
        })
        .then(data => {
            setdata(data);
            setIspending(false);
            setError(null);
        })
        .catch(err => {
            if (err.name === 'AbortError') {
                console.log('fetch aborted');
            } else {
                setIspending(false);
                setError(err.message);
            }
        });
    }, [url]);

    return { data, ispending, error };
}

export default useFetch;