import '../../css/adventures/AdventureDetails.css';
import React, { useState, useEffect } from 'react';



const AdventureDetails = ({ props }) => {

    const [activeButton, setActiveButton] = useState(1);

    const handleButtonClick = (day) => {
        setActiveButton(day);
    };

    const [mediaUrls, setMediaUrls] = useState([]);

    useEffect(() => {
        // Fetch media URLs from WordPress
        const fetchMedia = async () => {
            try {
                const urls = await Promise.all(props.map(async item => {
                    const response = await fetch(`https://mrt.abovedigital.co/wp-json/wp/v2/media/${item.image}`);
                    if (response.ok) {
                        const data = await response.json();
                        return data.source_url;
                    } else {
                        console.error(`Failed to fetch media with ID ${item.image}`);
                        return null;
                    }
                }));
                setMediaUrls(urls);
            } catch (error) {
                console.error('Error fetching media:', error);
            }
        };

        fetchMedia();
    }, [props]);



    return (
        <div id="section-details" className="anchored">
            <div id="section-details-anchor" className="anchor"></div>
            <div className="container full">
                <div className="row">
                    <div className="col col-45">
                        <div className="image-holder">
                            {props.map((item, index) => (
                                mediaUrls[index] && (
                                    <img
                                        key={index}
                                        src={mediaUrls[index]}
                                        alt={item.image_alt_description}
                                        draggable="false"
                                        data-day-img={index + 1}
                                        className={activeButton === index + 1 ? "show" : "hide"}/>
                                )
                            ))}
                        </div>

                    </div>
                    <div className="col col-15">
                        <div className="btn-holder">
                            {props.map((item, index) => (
                                <button key={index} onClick={() => handleButtonClick(index + 1)} className={activeButton === index + 1 ? "active" : ""} data-day-btn={index + 1}>
                                    <span className="day">DAY {index + 1}:</span>
                                    <span className="title">{item.title}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="col col-40">
                        <div className="details-holder">
                            {props.map((item, index) => (
                                <div 
                                    key={index} 
                                    data-day-detail={index + 1} 
                                    className={activeButton === index + 1 ? "details show" : "details hide"} 
                                    dangerouslySetInnerHTML={{ __html: item.description.replace(/<\/?span[^>]*>/g, (match) => {
                                        if (match.includes('font-weight: 400')) {
                                          return '<p>';
                                        } else {
                                          return '</p>';
                                        }
                                      })}}>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdventureDetails;
