import React from 'react'
import Brands from '../sections/Brands';
import Header from '../sections/header';
import NextSteps from '../sections/nextsteps';
import Footer from '../sections/footer';
import CanopyUppericons from '../sections/UtecanopySection/UtecanopyUppericons.jsx';
import UtecanopyBanner from '../sections/UtecanopySection/UtecanopyBanner.jsx';
import SecNav from '../sections/SecNav';
import UteCanopyFeature from '../sections/UtecanopySection/UtecanopyFeature.jsx';
import UteCanopySizes from '../sections/UtecanopySection/UtecanopySizes.jsx';
import UtecanopyOptions from '../sections/UtecanopySection/UtecanopyOptions.jsx';
import UtecanopyAccessories from '../sections/UtecanopySection/UtecanopyAccessories.jsx';
import UtecanopyMatch from '../sections/UtecanopySection/UtecanopyMatch.jsx';
const UteCanopy = () => {
    return (
        <>
            <Header title="Aluminium Ute Canopies - Market Leaders - Brisbane - Sydney - Perth" />
            <CanopyUppericons/>
            <UtecanopyBanner/>
            <SecNav />
            <UteCanopyFeature/>
            <UteCanopySizes/>
            <UtecanopyOptions/>
            <UtecanopyAccessories/>
            <UtecanopyMatch/>
            <Brands />
            <NextSteps />
            <Footer />
        </>
    )
}

export default UteCanopy;