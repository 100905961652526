import "../../css/aluminium-toolboxes/ToolBoxesLeftImage.css";
import MRT13Ute from "../../media/mrt13-on-ute.png";

const UteToolboxesforWork = () => {
    return ( 
      <div className="section-mrt-blocks image-left-background dark">
        <div id="ute-toolboxes-for-work" className="anchor-holder"></div>
        <div className="container fullwidth text-align-left">
          <div className="mrt-row">
            <div className="mrt-col col-50">
              <div className="image-holder">
                <img src={MRT13Ute} alt="MRT Toolbox on Ute"/>
              </div>
            </div>
            <div className="mrt-col col-50">
              <div className="info-holder">
                <p className="mrt-subtitle"></p>
                <h3 className="mrt-title">UTE TOOLBOXES FOR WORK</h3>
                <p className="mrt-desc">MRT ute toolboxes are an important piece of equipment for working tradespeople as they protect their most valuable gear from corrosion and theft both onsite and overnight. Our toolboxes have a robust and long-lasting design that is proven to save tradespeople money and time in the long-term.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default UteToolboxesforWork;
  