import React, { useEffect, useState } from "react";
import "../css/sec-nav.css";

const SecNav = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const secNav = document.getElementById("sec-nav");
      const secNavPosition = secNav.offsetTop;
      const scrollY = window.scrollY;
      
      setIsSticky(window.scrollY > secNavPosition && window.scrollY > 1000);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div id="sec-nav" className={isSticky ? "sticky" : ""}>
        <div className="section-nav">
          <div className="content-holder">
            <a href="#section-three-anchor">ALLOY PACKAGE PRICES</a>
            <a href="#section-two-anchor">POWDER COAT PACKAGE PRICES</a>
            <a href="#section-four-anchor">INCLUSIONS</a>
            <a href="#section-five-anchor">GALLERY</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecNav;
