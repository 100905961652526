import "../../css/landing/AluminiumUteTrays.css";
import MRTaut from "../../media/mrt-aluminium-ute-tray.png";


const AluminiumUteTrays = () => {
  return (
    <div id="section-4" className="section anchored">
      <div id="section-4-anchor" className="anchor"></div>

      <div className="container wider">
        <div className="row">
          <div className="col col-40">
            <div className="content-holder">
              <h2 className="title">Aluminium Ute Trays</h2>
              <p className="desc">
                MRT provides a range of high-quality, durable aluminium trays designed to perfectly fit MRT ute canopies. Available in widths of 1800mm and 1900mm, and lengths from 1650mm to 2400mm, MRT's trays are constructed from lightweight yet sturdy aluminium and can carry loads of up to 1000kg. Choose between the traditional flatbed ute-tray or the "complete tray", which includes heavy-duty double wall sides, recessed latches, rope rails, a headboard, and a 76mm front headboard tube for added convenience and versatility.
              </p>
            </div>
          </div>
          <div className="col col-60">
            <div className="img-holder">
              <img
                loading="lazy"
                src={MRTaut}
                alt="mrt ute tray with undertray drawer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AluminiumUteTrays;
