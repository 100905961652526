import React from 'react'
import '../../css/ute-canopy/UtecanopyOptions.css'
import '../../css/ute-canopy/Utecanopy.css'
import BackView from '../../media/UteCanopy/Canopy-options-back-view-for-ute-canopy.png'
import SpareWheel from '../../media/UteCanopy/Spare-tire-carrier-300-1.png'
import Ladder from '../../media/UteCanopy/Ladder-300-1.png'
import JerryCan from '../../media/UteCanopy/Jerry-Can-holder-2.png'
import LadderRacks from '../../media/UteCanopy/ladder-racks.png'
import RoofRacks from '../../media/UteCanopy/roof-racks.png'
import XSeriesLadderRacks from '../../media/UteCanopy/x-series-ladder-racks.png'
import XSeriesRoofRacks from '../../media/UteCanopy/x-series-roof-racks.png'
import Windows from '../../media/UteCanopy/windows.png'
import CanopyWidth from '../../media/UteCanopy/canopy-width.png'
import KeylessEntry from '../../media/UteCanopy/kes.png'

const UtecanopyOptions = () => {
    return (
      <>
        <div id="options" className="section-mrt-blocks anchored">
          <div id="options-anchor" className="anchor"></div>

          <div className="container fullwidth">
            <div className="mrt-row">
              <div className="mrt-col col-100">
                <div className="title-holder">
                  <h2>OPTIONS</h2>
                </div>
              </div>
            </div>

            <div className="mrt-row gradient-bg-rev">
              <div className="mrt-col col-50">
                <div className="info-holder bg-clear">
                  <h3>Known for our quality, recognised for our options.</h3>

                  <p>
                    At MRT there are many things that set us apart from our
                    competition. Our quality, attention to detail, wide range of
                    impressive options and of course our competitive pricing.
                    Our research and development team is constantly on the hunt
                    for innovative ways to improve the MRT adventure. From the
                    cutting-edge design of our X-series product line to the
                    patent-pending technology used in our keyless entry system,
                    we have an upgrade for every style of adventure.
                  </p>
                </div>
              </div>

              <div className="mrt-col col-50">
                <div className="image-holder">
                  {/* <img
                    src="https://www.mrt.com.au/wp-content/uploads/2022/08/Canopy-options-back-view-for-ute-canopy.png"
                    alt="MRT canopy options back view ute canopy"
                  /> */}
                  <img
                    src={BackView}
                    alt="MRT canopy options back view ute canopy"
                  />
                  <div className="option sparewheelcarrier">
                    {/* <img
                      src="https://www.mrt.com.au/wp-content/uploads/2022/08/Spare-tire-carrier-300-1.png"
                      alt="SPARE WHEEL CARRIER"
                    /> */}
                    <img
                      src={SpareWheel}
                      alt="SPARE WHEEL CARRIER"
                    />
                    <p className="name">SPARE WHEEL CARRIER</p>
                    <a href="#section-spare-wheel-carrier-info">Learn more</a>
                  </div>
                  <div className="option adjustableladder">
                    {/* <img
                      src="https://www.mrt.com.au/wp-content/uploads/2022/08/Ladder-300-1.png"
                      alt="ADJUSTABLE LADDER"
                    /> */}
                    <img
                      src={Ladder}
                      alt="ADJUSTABLE LADDER"
                    />
                    <p className="name">ADJUSTABLE LADDER</p>
                    <a href="#section-canopy-ladder-info">Learn more</a>
                  </div>
                  <div className="option jerrycanholder">
                    {/* <img
                      src="https://www.mrt.com.au/wp-content/uploads/2022/08/Jerry-Can-holder-2.png"
                      alt="JERRY CAN HOLDER"
                    /> */}
                    <img
                      src={JerryCan}
                      alt="JERRY CAN HOLDER"
                    />
                    <p className="name">JERRY CAN HOLDER</p>
                    <a href="#section-jerry-can-info">Learn more</a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="mrt-row"
              style={{
                background:
                  "linear-gradient(180deg, #1F1F1F 0, #3F3F3F 30.84%)",
              }}
            >
              <div className="mrt-col col-50 left">
                <div className="holder">
                  <div className="row">
                    <div className="col col-sm-6">
                      <h5>RANGE OF LADDER RACK OPTIONS</h5>

                      <div className="option-holder one">
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/ladder-racks.png"
                          alt="MRT ladder racks ute canopy"
                        /> */}
                        <img
                          src={LadderRacks}
                          alt="MRT ladder racks ute canopy"
                        />

                        <p className="option-title">STANDARD LADDER RACKS</p>
                        <a
                          href="#section-canopy-ladder-racks-anchor"
                          className="option-more"
                        >
                          Learn more
                        </a>
                      </div>

                      <div className="option-holder two">
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/roof-racks.png"
                          alt="MRT roof racks ute canopy"
                        /> */}
                        <img
                          src={RoofRacks}
                          alt="MRT roof racks ute canopy"
                        />

                        <p className="option-title">STANDARD ROOF RACKS</p>
                        <a
                          href="#section-canopy-roof-racks-anchor"
                          className="option-more"
                        >
                          Learn more
                        </a>
                      </div>

                      <div className="option-holder three">
                        <span className="option-label">TIE DOWN POINTS</span>
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/x-series-ladder-racks.png"
                          style={{ paddingTop: "20px" }}
                          alt="MRT x-series ladder racks ute canopy"
                        /> */}
                        <img
                          src={XSeriesLadderRacks}
                          style={{ paddingTop: "20px" }}
                          alt="MRT x-series ladder racks ute canopy"
                        />

                        <p className="option-title">X-SERIES LADDER RACKS</p>
                        <a
                          href="#section-canopy-ladder-racks-anchor"
                          className="option-more"
                        >
                          Learn more
                        </a>
                      </div>

                      <div className="option-holder four">
                        <span className="option-label">
                          LADDER RACK ROLLERS
                        </span>
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/x-series-roof-racks.png"
                          style={{ paddingTop: "20px" }}
                          alt="MRT x-series roof racks ute canopy"
                        /> */}
                        <img
                          src={XSeriesRoofRacks}
                          style={{ paddingTop: "20px" }}
                          alt="MRT x-series roof racks ute canopy"
                        />
                        <p className="option-title">X-SERIES ROOF RACKS</p>
                        <a
                          href="#section-canopy-roof-racks-anchor"
                          className="option-more"
                        >
                          Learn more
                        </a>
                      </div>
                    </div>

                    <div className="col col-sm-6">
                      <h5>RANGE OF WINDOW OPTIONS</h5>

                      <div className="option-holder one">
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/uploads/2022/08/windows.png"
                          alt="MRT windows option ute canopy"
                        /> */}
                        <img
                          src={Windows}
                          alt="MRT windows option ute canopy"
                        />
                        <p className="option-title"></p>
                        <a href="#section-windows-info" className="option-more">
                          Learn more
                        </a>
                      </div>

                      <div className="option-holder two">
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/canopy-width.png"
                          alt="MRT canopy width ute canopy"
                        /> */}
                        <img
                          src={CanopyWidth}
                          alt="MRT canopy width ute canopy"
                        />
                        <p className="option-title">
                          AVAILABLE IN RANGE OF SIZES
                        </p>
                        <a href="#sizes-anchor" className="option-more">
                          Learn more
                        </a>
                      </div>

                      <div className="option-holder three">
                        {/* <img
                          src="https://www.mrt.com.au/wp-content/themes/mrt/img/canopy/upgrade-options/kes.png"
                          alt="MRT keyless entry system ute canopy"
                        /> */}
                        <img
                          src={KeylessEntry}
                          alt="MRT keyless entry system ute canopy"
                        />
                        <p className="option-title">KEYLESS ENTRY SYSTEM</p>
                        <a
                          href="#section-canopy-keyless-entry"
                          className="option-more"
                        >
                          Learn more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mrt-col col-50 right">
                <div className="holder">
                  <div className="label solarvent">
                    <p className="option-title">SOLAR VENT</p>
                    <a href="#section-solar-vent-info" className="option-more">
                      Learn more
                    </a>
                  </div>
                  <div className="label liftoffsystem">
                    <p className="option-title">LIFT OFF SYSTEM</p>
                    <a
                      href="#section-canopy-liftoff-info"
                      className="option-more"
                    >
                      Learn more
                    </a>
                  </div>
                  <div className="label powdercoatfinish">
                    <p className="option-title">POWDER COAT FINISH</p>
                    <a href="#section-powder-coat" className="option-more">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default UtecanopyOptions;