import React from "react";
import "../css/Brand.css";
import ford from "../media/brands/ford.png";
import nissan from "../media/brands/nissan.png";
import volkswagen from "../media/brands/volkswagen.png";
import mercedesbenz from "../media/brands/mercedesbenz.png";
import isuzu2 from "../media/brands/isuzu2.png";
import toyota from "../media/brands/toyota.png";
import mazda from "../media/brands/mazda.png";
import mitsubishi from "../media/brands/mitsubishi.png";
import holden from "../media/brands/holden.png";
import tata from "../media/brands/tata.png";

const Brands = () => {
  return (
    <>
      <div id="brands" className="section-mrt-blocks">
        <div id="brands-anchor"></div>

        <div className="container fullwidth">
          <div className="mrt-row">
            <div className="mrt-col col-100">
              <div className="title-holder">
                <h2>BRANDS</h2>
              </div>
            </div>
          </div>

          <div className="mrt-row gradient-bg-rev">
            <div className="mrt-col col-100">
              <div className="info-holder">
                <p>
                  MRT Aluminium Ute Canopies are made to suit these leading
                  brands and more!
                </p>

                <div className="link-holder">
                  <a
                    href="https://www.ford.com.au/commercial/ranger/"
                    target="_blank"
                  >
                    <img
                      src={ford}
                      className="mrt-car-image"
                      alt="MRT ford ute canopy"
                    />
                  </a>

                  <a
                    href="https://www.nissan.com.au/vehicles/browse-range/navara.html"
                    target="_blank"
                  >
                    <img
                      src={nissan}
                      className="mrt-car-image"
                      alt="MRT nissan ute canopy"
                    />
                  </a>

                  <a
                    href="https://www.volkswagen.com.au/en/models/amarok.html"
                    target="_blank"
                  >
                    <img
                      src={volkswagen}  
                      className="mrt-car-image"
                      alt="MRT volkswagen ute canopy"
                    />
                  </a>

                  <a
                    href="https://www.mercedes-benz.com.au/vans/en/x-className"
                    target="_blank"
                  >
                    <img
                      src={mercedesbenz}
                      className="mrt-car-image"
                      alt="MRT mercedes ute canopy"
                    />
                  </a>

                  <a href="https://www.isuzuute.com.au/" target="_blank">
                    <img
                      src={isuzu2}
                      className="mrt-car-image"
                      alt="MRT isuzu ute canopy"
                    />
                  </a>

                  <a href="https://www.toyota.com.au/hilux" target="_blank">
                    <img
                      src={toyota}
                      className="mrt-car-image"
                      alt="MRT toyota ute canopy"
                    />
                  </a>

                  <a
                    href="https://www.mazda.com.au/utes-range/"
                    target="_blank"
                  >
                    <img
                      src={mazda}
                      className="mrt-car-image"
                      alt="MRT mazda ute canopy"
                    />
                  </a>

                  <a
                    href="https://www.mitsubishi-motors.com.au/vehicles/triton"
                    target="_blank"
                  >
                    <img
                      src={mitsubishi}
                      className="mrt-car-image"
                      alt="MRT mitsubishi ute canopy"
                    />
                  </a>

                  <a href="https://www.holden.com.au/ute#Range" target="_blank">
                    <img
                      src={holden}
                      className="mrt-car-image"
                      alt="MRT holden ute canopy"
                    />
                  </a>

                  <a href="https://www.tatamotors.com.au/" target="_blank">
                    <img
                      src={tata}
                      className="mrt-car-image"
                      alt="MRT tata ute canopy"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
