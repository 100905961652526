import useFetch from "../../../useFetch";
import "../../css/adventures/AdventureGallery.css";
import { useState, useEffect } from 'react';

const AdventureGallery = ({ props }) => {
	const {data: vidsplash_obj, ispending: vidsplash_obj_ispending, error: vidsplash_obj_error} = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/media/'+props.video_splash_image);
	var vidsplash_img = null;
	if (vidsplash_obj !== null){
		vidsplash_img = vidsplash_obj.source_url;
	}

	function displayVid() {
		var placeholder = document.querySelector('.video-placeholder');
		var video = props.video_embed_code;
		placeholder.outerHTML = video;
	}

	const [isLightboxOpen, setIsLightboxOpen] = useState(false);
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [imagesToShow, setImagesToShow] = useState([]);
	const [altTags, setAltTags] = useState([]);

	useEffect(() => {
		const fetchImageData = async () => {
			const fetchedData = await Promise.all(
				props.image_gallery.map(async (item) => {
					const response = await fetch(
						`https://www.mrt.com.au/wp-json/wp/v2/media/${item.gallery_item}`
					);
					const data = await response.json();
					return {
						imageUrl: data.source_url,
						altText: data.title.rendered,
					};
				})
			);

			const fetchedImageData = fetchedData.map((item) => item.imageUrl);
			const altTagsData = fetchedData.map((item) => item.altText);

			setImagesToShow(fetchedImageData);
			setAltTags(altTagsData);
		};

		fetchImageData();
	}, [props.image_gallery]);


	// Add event listener for Escape key
	const handleEscapeKey = (event) => {
		if (event.key === 'Escape') {
			closeLightbox();
		}
	};
	const openLightbox = (index) => {
		setCurrentImageIndex(index);
		setIsLightboxOpen(true);

		document.addEventListener('keydown', handleEscapeKey);
	};

	const closeLightbox = () => {
		setIsLightboxOpen(false);

		// Remove event listener for Escape key
		document.removeEventListener('keydown', handleEscapeKey);
	};

	const showPrevImage = () => {
		setCurrentImageIndex(
			(currentImageIndex - 1 + imagesToShow.length) % imagesToShow.length
		);
	};

	const showNextImage = () => {
		setCurrentImageIndex((currentImageIndex + 1) % imagesToShow.length);
	};

	const handleOutsideClick = (event) => {
		if (event.target.classList.contains('lightbox-container')) {
			closeLightbox();
		}
	  };

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	});
	return (
		<div id="section-gallery" className="anchored">
			<div id="section-gallery-anchor" className="anchor"></div>
			<div className="container full">
				<div className="row video-section">
					<div className="col col-100">
						<div className="video-holder">
							<img
								src={vidsplash_img}
								alt={props.video_splash_image_alt_description}
								className="video-placeholder"
								onClick={displayVid}
							/>
						</div>
					</div>
				</div>
				<div className="gallery">
					<div className="gallery-container">
						<div className="row">
							<div className="col col-60">
								<div className="gallery-item main-image"
									onClick={() => openLightbox(0)}>
									<img src={imagesToShow[0]} alt={altTags[0]} />
								</div>
							</div>
							<div className="col col-40">
								<div className="gallery-item top-image"
									onClick={() => openLightbox(1)}>
									<img src={imagesToShow[1]} alt={altTags[1]} />
								</div>
								<div className="row">
									<div className="col col-50">
										<div className="gallery-item small-image"
											onClick={() => openLightbox(2)}>
											<img src={imagesToShow[2]} alt={altTags[2]} />
										</div>
									</div>
									<div className="col col-50">
										<div className="gallery-item small-image"
											onClick={() => openLightbox(3)}>
											<img src={imagesToShow[3]} alt={altTags[3]} />
											<span className="more-count">+{imagesToShow.length - 4}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{isLightboxOpen && (
					<div className="lightbox-container">
						<span className="close-btn" onClick={closeLightbox}>
							<i className="fa-regular fa-xmark"></i>
						</span>
						<button className="prev-btn" onClick={showPrevImage}>
							<i className="fa-regular fa-chevron-left"></i>
						</button>
						<button className="next-btn" onClick={showNextImage}>
							<i className="fa-regular fa-chevron-right"></i>
						</button>
						<div className="lightbox-content">
							<img
								className="lightbox-image"
								src={imagesToShow[currentImageIndex]}
								alt={altTags[currentImageIndex]}
							/>
							<div className="image-count">
								{currentImageIndex + 1} / {imagesToShow.length}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)

}

export default AdventureGallery;