
import Header from "../sections/header";
import NextSteps from '../sections/nextsteps';
import Footer from "../sections/footer";
import AccessoriesKES from "../sections/accessories/AccessoriesKes";
import AccessoriesCategory from "../sections/accessories/AccessoriesCategory";
import useFetch from "../../useFetch";

const Accessories = ({accessoriesProducts}) => {
    // const { data, ispending, error } = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/pages/19')
    return ( 
        <>
        <Header title="Accessories For Ute Canopies - Ute Trays - Toolboxes - MRT" />
        <AccessoriesCategory accessoriesProducts = {accessoriesProducts}/>
        <AccessoriesKES />
        <NextSteps />
        <Footer />
        </>

     );
}
 
export default Accessories;