// header
import Title from "./title";
import React from "react";
import "../css/header.css";
import logo from "../media/mrtlogo.png";
import MiniSearchBar from './MiniSearchBar';
import TopArea from "./TopArea";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const Header = ({ title, products, products_loading }) => {

	const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

	const handlesearchclick = () => {
		setIsSearchBarOpen(!isSearchBarOpen);
	}

	const [isScrolled, setIsScrolled] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const scrollThreshold = 1;
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		const primaryMenuLinks = document.querySelectorAll('.nav-menu > li > a');
		const handleMenuClose = () => {
			setIsMenuOpen(false);
			document.body.style.overflow = 'auto';
		};

		primaryMenuLinks.forEach((link) => {
			link.addEventListener('click', handleMenuClose);
		});

		return () => {
			primaryMenuLinks.forEach((link) => {
				link.removeEventListener('click', handleMenuClose);
			});
		};
	}, []);

	useEffect(() => {
		const handleMenuToggle = () => {
			setIsMenuOpen(!isMenuOpen);
  			document.body.style.overflow = isMenuOpen ? 'auto' : 'hidden';
		};
		const menuToggle = document.querySelector('.dl-trigger');
		menuToggle.addEventListener('click', handleMenuToggle);
		const mobileMenuSlideClose = document.querySelector('.mobile-menu-slide-close');
		mobileMenuSlideClose.addEventListener('click', handleMenuToggle);

		return () => {
			menuToggle.removeEventListener('click', handleMenuToggle);
			mobileMenuSlideClose.removeEventListener('click', handleMenuToggle);
		};
	}, [isMenuOpen]);

	const [headerStyle, setheaderStyle] = useState({
		top: "-900px",
		transition: "top 0.5s",
	});
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= scrollThreshold) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}

			const currentScrollPos = window.scrollY;

			if (prevScrollPos > currentScrollPos) {
				setheaderStyle({ top: `${prevScrollPos - currentScrollPos}px`, transition: "0s" });
				setheaderStyle({ top: "0px", transition: "0s" });
			} else {
				setheaderStyle({ top: "-900px", transition: "0s" });
			}
			setPrevScrollPos(currentScrollPos);

		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [scrollThreshold, prevScrollPos]);


	const [ordercount, setOrderCount] = useState(0);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 1212);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);



	useEffect(() => {
		const parentMenuToggle = document.querySelectorAll('.menu-item-parent-toggle');

		const handleParentMenuToggle = (e) => {
			const parent = e.target.closest('.menu-item-has-children');
			const parentMenuToggle = parent.querySelector('.menu-item-parent-toggle');
			const subMenu = parent.querySelector('.sub-menu');
			parent.classList.toggle('menu-item-open');
			subMenu.classList.toggle('sub-menu-open');
			parentMenuToggle.classList.toggle('rotate');
		};

		parentMenuToggle.forEach((toggle) => {
			toggle.addEventListener('click', handleParentMenuToggle);
		});

		return () => {
			parentMenuToggle.forEach((toggle) => {
				toggle.removeEventListener('click', handleParentMenuToggle);
			});
		};
	}, []);


	return (
		<>
			<Title title={title} />
			<a href="#page" className={isScrolled ? "back-to-top isScrolled" : "back-to-top"} onClick={scrollToTop}>
				<i className="fa-solid fa-angle-up fa-sm"></i>
			</a>
			<div id="site-header-wrapper" style={isMobile ? headerStyle : null}>
				<header id="site-header" className="site-header animated-header mobile-menu-layout-slide-vertical ios-load" role="banner">
					<div className="mrt-top-banner">
						<div className="slideshow-container">
							<div className="text">
								<p>
									<a href="https://www.mrt.com.au/borderlandexplorers/">
										The Big Lap - An X-Series Adventure. Meet Matty &amp; Em!
									</a>
								</p>
							</div>
						</div>
					</div>
					{isMobile ? null :
						<div className="top-area-background">
							<TopArea />
						</div>
					}
					<div className="header-background">
						<div className="container">
							<div className="header-main logo-position-left header-layout-default header-style-3">
								<div className="mobile-minicart-overlay"></div>
								<div className="m-box">
									<div className="site-title">
										<div className="site-logo" >
											<a href="/" rel="home">
												<span className={isScrolled ? "logo isScrolled" : "logo"} >
													<img
														src={logo}
														alt="MRT"
														className={isScrolled ? "header_logo isScrolled" : "header_logo"}
													/>
												</span>
											</a>
										</div>
									</div>
									<nav id="primary-navigation" className="site-navigation primary-navigation" role="navigation">
										<button className="menu-toggle dl-trigger">
											Primary Menu
											<span className="menu-line-1"></span>
											<span className="menu-line-2"></span>
											<span className="menu-line-3"></span>
										</button>
										<div className={isMenuOpen ? "mobile-menu-slide-wrapper top opened" : "mobile-menu-slide-wrapper top"}>
											<button className="mobile-menu-slide-close" type="button"></button>
											<ul id="primary-menu" className={isMobile ? "nav-menu styled" : "nav-menu styled no-responsive"}>
												<li id="menu-item-6352" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-6352 megamenu-first-element">
													<Link to="/toolboxes">Toolboxes</Link>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-1506" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1506 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/undertray-toolboxes/">Under Tray Toolboxes</a>
														</li>
														<li id="menu-item-1645" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1645 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/generator-toolboxes/">Generator Toolboxes</a>
														</li>
														<li id="menu-item-1737" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1737 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/3-4-opening-toolboxes/">3/4 Opening Toolboxes</a>
														</li>
														<li id="menu-item-1768" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1768 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/chest-style-toolboxes/">Chest Style Toolboxes</a>
														</li>
														<li id="menu-item-1762" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1762 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/crossdeck-toolboxes/">Crossdeck Toolboxes</a>
														</li>
														<li id="menu-item-1678" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1678 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/gullwing-toolboxes/">Gullwing Toolboxes</a>
														</li>
														<li id="menu-item-1679" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1679 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/rectangular-plain-toolboxes/">Rectangular Plain Toolboxes</a>
														</li>
														<li id="menu-item-1709" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1709 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/drawbar-toolboxes/">Drawbar Toolboxes</a>
														</li>
														<li id="menu-item-1719" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1719 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/full-opening-toolboxes/">Full Opening Toolboxes</a>
														</li>
														<li id="menu-item-15627" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-15627 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-toolboxes/dog-boxes/">Dog Boxes</a>
														</li>
														<li id="menu-item-15638" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-15638 megamenu-first-element">
															<a href="https://www.mrt.com.au/x-series-dog-boxes/">X-Series Dog Boxes</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-6369" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-6369 megamenu-first-element">
													<Link to="/accessories">Accessories</Link>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-15010" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-15010 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/keyless-entry-system/">Keyless Entry System</a>
														</li>
														<li id="menu-item-16291" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16291 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/x-series-adjustable-shelving/">X-Series Adjustable Shelving and Drawer Units</a>
														</li>
														<li id="menu-item-3576" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3576 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/x-series-drawer-modules/">X Series Drawer Modules</a>
														</li>
														<li id="menu-item-3579" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3579 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/fridge-slides/">Fridge Slides</a>
														</li>
														<li id="menu-item-7890" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7890 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/fitting-kits/">Fitting Kits</a>
														</li>
														<li id="menu-item-6935" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-6935 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/flooring/">Flooring</a>
														</li>
														<li id="menu-item-3581" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-3581 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/dog-box-accessories/">Dog Box Accessories</a>
														</li>
														<li id="menu-item-15543" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-15543 megamenu-first-element">
															<a href="https://www.mrt.com.au/accessories/jerry-can-holders/">Jerry Can Holders</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-8006" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-8006 megamenu-first-element">
												<Link to="/aluminium-ute-canopies">Ute Canopies</Link>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-22521" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-parent menu-item-22521 megamenu-first-element">
															<a href="#">Standard Canopy <i className="fa-regular fa-angle-right fa-xs"></i></a>
															<span className="menu-item-parent-toggle">
																<i className="fa-sharp fa-solid fa-angle-right"></i>
															</span>
															<ul className="sub-menu styled">
																<li id="menu-item-4874" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4874 megamenu-first-element">
																	<a href="https://www.mrt.com.au/product/aluminium-ute-canopies-part-tray-canopy/">Part Tray Canopy</a>
																</li>
																<li id="menu-item-4876" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-4876 megamenu-first-element">
																	<a href="https://www.mrt.com.au/product/aluminium-ute-canopies-dual-cab-canopy/">Dual Cab Canopy</a>
																</li>
																<li id="menu-item-17888" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-17888 megamenu-first-element">
																	<a href="https://www.mrt.com.au/product/aluminium-ute-canopies-extra-cab-canopy/">Extra Cab Canopy</a>
																</li>
																<li id="menu-item-17889" className="menu-item menu-item-type-post_type menu-item-object-product menu-item-17889 megamenu-first-element">
																	<a href="https://www.mrt.com.au/product/aluminium-ute-canopies-single-cab-canopy/">Single Cab Canopy</a>
																</li>
															</ul>
														</li>
														<li id="menu-item-22520" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-22520 megamenu-first-element">
															<a href="https://www.mrt.com.au/x-series-chassis-mount-canopy-product/">X-Series Chassis Mount Canopy</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-13378" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-13378 megamenu-first-element">
													<a href="https://www.mrt.com.au/aluminium-ute-trays/">Ute Trays</a>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-18050" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18050 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-ute-trays/complete-tray/">Complete Tray</a>
														</li>
														<li id="menu-item-18049" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-18049 megamenu-first-element">
															<a href="https://www.mrt.com.au/aluminium-ute-trays/tray-only/">Flatbed Ute Tray</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-18796" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-parent menu-item-18796 megamenu-first-element">
													<a href="https://www.mrt.com.au/tray-and-canopy-packages/">Packages</a>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-23896" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-23896 megamenu-first-element">
															<a href="https://www.mrt.com.au/packages/companion-package/">Companion Package</a>
														</li>
														<li id="menu-item-20974" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20974 megamenu-first-element">
															<a href="https://www.mrt.com.au/packages/tradesman-package/">Tradesman Package</a>
														</li>
														<li id="menu-item-20003" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20003 megamenu-first-element">
															<a href="https://www.mrt.com.au/packages/explorer-package/">Explorer Package</a>
														</li>
														<li id="menu-item-20013" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20013 megamenu-first-element">
															<a href="https://www.mrt.com.au/packages/initiator-package/">Initiator Package</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-7245" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-7245 megamenu-first-element">
													<a href="https://www.mrt.com.au/keyless-entry-range/">Keyless Entry Range</a>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-17894" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17894 megamenu-first-element">
															<a href="https://www.mrt.com.au/keyless-entry-range/#keyless-entry-canopies">Canopy Range</a>
														</li>
														<li id="menu-item-17896" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-17896 megamenu-first-element">
															<a href="https://www.mrt.com.au/keyless-entry-range/#keyless-entry--br-diy-kits">DIY Kits</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-16024" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-16024 megamenu-first-element">
													<a href="https://www.mrt.com.au/gallery/">Gallery</a>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-17127" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-17127 megamenu-first-element">
															<a href="https://www.mrt.com.au/gallery/">MRT GEAR</a>
														</li>
														<li id="menu-item-20032" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20032 megamenu-first-element">
															<a href="https://www.mrt.com.au/mymrtgear/">#mymrtgear</a>
														</li>
													</ul>
												</li>
												<li id="menu-item-2443" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-2443 megamenu-first-element">
													<a href="https://www.mrt.com.au/contact/">Contact</a>
													<span className="menu-item-parent-toggle">
														<i className="fa-sharp fa-solid fa-angle-right"></i>
													</span>
													<ul className="sub-menu styled">
														<li id="menu-item-13074" className="menu-item menu-item-type-post_type menu-item-object-wpseo_locations menu-item-13074 megamenu-first-element">
															<a href="https://www.mrt.com.au/locations/brisbane-ute-canopies-and-ute-trays/">Brisbane UTE Canopies &amp; UTE Trays</a>
														</li>
														<li id="menu-item-13070" className="menu-item menu-item-type-post_type menu-item-object-wpseo_locations menu-item-13070 megamenu-first-element">
															<a href="https://www.mrt.com.au/locations/gold-coast-ute-canopies-and-ute-trays/">Gold Coast UTE Canopies &amp; UTE Trays</a>
														</li>
														<li id="menu-item-13072" className="menu-item menu-item-type-post_type menu-item-object-wpseo_locations menu-item-13072 megamenu-first-element">
															<a href="https://www.mrt.com.au/locations/perth-ute-canopies-and-ute-trays/">Perth UTE Canopies &amp; UTE Trays</a>
														</li>
														<li id="menu-item-13076" className="menu-item menu-item-type-post_type menu-item-object-wpseo_locations menu-item-13076 megamenu-first-element">
															<a href="https://www.mrt.com.au/locations/sydney-ute-canopies-and-ute-trays/">Sydney Ute Canopies &amp; Ute Trays</a>
														</li>
													</ul>
												</li>
												<li className="menu-item menu-item-search adv">
													{isMobile ? null :
														<a className="searchicon" onClick={handlesearchclick}>
															{isSearchBarOpen ? <i className="fa-regular fa-xmark fa-xl"></i> : <i className="fa-regular fa-magnifying-glass fa-xl"></i>}
														</a>
													}
													<div className={isMobile ? "minisearch invert active" : isSearchBarOpen ? "minisearch invert active" : "minisearch invert"} >
														{!products_loading && <MiniSearchBar products={products} />}
													</div>
												</li>
												{/* <li className="menu-item menu-item-widgets mobile-only">
													<div className="menu-item-socials">
														<div className="socials inline-inside">
															<a className="socials-item" href="https://www.facebook.com/mrtaustralia/" target="_blank" rel="noopener" title="Facebook">
																<i className="socials-item-icon facebook "></i>
															</a>
															<a className="socials-item" href="#" target="_blank" rel="noopener" title="LinkedIn">
																<i className="socials-item-icon linkedin "></i>
															</a>
															<a className="socials-item" href="#" target="_blank" rel="noopener" title="Twitter">
																<i className="socials-item-icon twitter "></i>
															</a>
															<a className="socials-item" href="https://www.instagram.com/mrt_australia/" target="_blank" rel="noopener" title="Instagram">
																<i className="socials-item-icon instagram "></i>
															</a>
															<a className="socials-item" href="#" target="_blank" rel="noopener" title="Pinterest">
																<i className="socials-item-icon pinterest "></i>
															</a>
															<a className="socials-item" href="https://www.youtube.com/channel/UCIfyriLiauAWT10mqpD3Qkg" target="_blank" rel="noopener" title="YouTube">
																<i className="socials-item-icon youtube "></i>
															</a>
														</div>
													</div>
												</li> */}
											</ul>
										</div>
									</nav>
								</div>
								<div className="menu-item-cart">
									<a href="https://www.mrt.com.au/cart/" className="minicart-menu-link empty circle-count">
										<i className="fa-regular fa-cart-shopping"></i>
										{ordercount > 0 && <span className="minicart-item-count">{ordercount}</span>}
									</a>
									{/* <div className="minicart invert">
										<div className="widget_shopping_cart_content">
											<div className="mobile-cart-header">
												<div className="mobile-cart-header-title title-h6">order request</div>
												<a className="mobile-cart-header-close" href="#">
													<span className="cart-close-line-1"></span>
													<span className="cart-close-line-2"></span>
												</a>
											</div>
											<ul className="cart_list product_list_widget ">
												<li className="empty">No products in the order request.</li>
											</ul>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
};

export default Header;
