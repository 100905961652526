import "../../css/landing/ManufacturingBanner.css";
import ManufacturiWildeing from "../../media/manufacturing-welding.jpg";
import AuOwnManufacturing from "../../media/australian-own-manufacturing-desktop.png";
import AuOwnMMobile from "../../media/australian-owned-manufacturing-mobile.png";

const ManufacturingBanner = () => {
    return (
      <div id="section-7" className="section anchored">
        <div id="section-7-anchor" className="anchor"></div>
        <div className="container boxed">
          <div className="row">
            <div className="col col-40">
              <div className="img-holder">
                <img
                  loading="lazy"
                  src={ManufacturiWildeing}
                  alt="australian manufacturing welding"
                />
              </div>
            </div>
            <div className="col col-60">
              <div className="content-holder">
                <h2 className="title">
                  <span className="hidden">Australian Owned Manufacturing</span>
                  <img
                    loading="lazy"
                    src={AuOwnManufacturing}
                    alt="Australian Owned Manufacturing Title"
                    className="desktop"
                  />
                  <img
                    loading="lazy"
                    src={AuOwnMMobile}
                    alt="Australian Owned Manufacturing Title"
                    className="mobile"
                  />
                </h2>
              </div>
              <div className="p-row">
                <div className="col col-50">
                  <p className="desc">
                    At MRT, we take pride in our ability to manufacture high-quality canopies and trays at an affordable price. MRT is an Australian owned company that has adapted a unique business model and offering for our customers. Harnessing the knowledge, skill and expertise learnt in the Automotive industry over many years, MRT have transferred this skill, talent and attention to detail into our very own purpose-built manufacturing facility in Vietnam, allowing us to offer the highest possible quality in products at the lowest possible price.
                  </p>
                  <p className="desc">
                    We oversee all aspects of production at our state-of-the-art factory in Vietnam, which spans over 10,000m2 and employs almost 100 skilled staff that are experts within their fields. Our factory includes a design and engineering team that are constantly evolving our extensive range of products and working
                  </p>
                </div>
                <div className="col col-50">
                  <p className="desc">
                    closely with our lead design engineer in Australia to bring the latest in automotive storage solutions and innovation to life. We manufacture not only finished products but also a vast amount of the components used in our Manufacturing such as hinges and locks, which allows us to maintain strict control over every aspect of the production process and deliver such an amazing product.
                  </p>
                  <p className="desc">
                    Being an Australian owned and managed factory means we can ensure that our products meet the highest standards of quality and design, while also keeping costs affordable for our customers. We are committed to providing a superior product that meets and exceeds expectations, and our unique manufacturing process is a key part of that commitment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ManufacturingBanner;
  