import React from 'react'
import '../../css/ute-canopy/UtecanopyAccessories.css'
import '../../css/ute-canopy/Utecanopy.css'
import Accessories from '../../media/UteCanopy/canopy-accessories.gif'
const UtecanopyAccessories = () => {
    return(
        <>
        <div id="accessories" className="section-mrt-blocks anchored">
            <div id="accessories-anchor" className="anchor"></div>

            <div className="container fullwidth">
                <div className="mrt-row">
                <div className="mrt-col col-100">
                    <div className="title-holder">
                    <h2>ACCESSORIES</h2>
                    </div>
                </div>
                </div>

                <div className="mrt-row gradient-bg-rev">
                <div className="mrt-col col-50">
                    <div className="info-holder bg-clear">
                    <h3>Ute Canopy Accessories</h3>
                    <p>
                        Our adjustable shelving + drawer modules have been designed to be
                        completely modular. They also come in a range of different sizes and
                        configurations that are fully interchangeable. You are able to
                        adjust the height of your shelves and drawers along with the
                        positioning of your entire drawer unit to completely customise the
                        setup for your style of MRT adventure.
                    </p>
                    <a href="https://www.mrt.com.au/accessories/">
                        <span className="hvr-ripple-out">
                        VIEW OUR FULL RANGE OF UTE CANOPY ACCESSORIES
                        </span>
                    </a>
                    </div>
                </div>

                <div className="mrt-col col-50">
                    <div className="image-holder">
                    {/* <img
                        src="https://www.mrt.com.au/wp-content/uploads/2022/08/canopy-accessories.gif"
                        alt="MRT ute canopy accessories"
                    /> */}
                    <img
                        src={Accessories}
                        alt="MRT ute canopy accessories"
                    />
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default UtecanopyAccessories;