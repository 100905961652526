import React from 'react'
import '../../css/ute-canopy/UtecanopyFeature.css';
import '../../css/ute-canopy/Utecanopy.css'
import CanopyFeature from './features-components/CanopyFeature';
import CanopyLift from '../../media/UteCanopy/Canopy-with-lift-off-system-1.png';
import WarrantyIcon from '../../media/UteCanopy/mrt-structural-warranty-icon.png';


const UteCanopyFeature = () => {

    return (
      <>
        <div id="features" className="section-mrt-blocks anchored">
          <div id="features-anchor" class="anchor"></div>
          <div className="container fullwidth">
            <div className="mrt-row">
              <div className="mrt-col col-100">
                <div className="title-holder">
                  <h2>FEATURES</h2>
                </div>
              </div>
            </div>
            <div className="mrt-row gradient-bg-rev">
              <div className="mrt-col col-100">
                <div className="mrt-row">
                  <div className="mrt-col col-50">
                    <div className="info-holder bg-clear">
                      <h3 className="title">TECH SPECS</h3>
                      <CanopyFeature />
                    </div>
                  </div>
                  <div className="mrt-col col-50">
                    <div className="image-holder">
                      <img
                        src={CanopyLift}
                        alt="MRT lift off system ute canopy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mrt-row">
                <div className="mrt-col col-50">
                    <div className="mrt-row">
                    <div className="mrt-col col-50 green">
                        <div className="info-holder bg-clear">
                        <img
                            src={WarrantyIcon}
                            alt="mrt structural warranty icon"
                        />
                        <h3>
                            3-Year Structural <br />
                            Warranty
                        </h3>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder"></div>
                    </div>
                    </div>
                    <div className="mrt-row">
                    <div className="mrt-col col-50 second">
                        <div className="image-holder"></div>
                    </div>
                    <div className="mrt-col col-50 white">
                        <div className="info-holder bg-clear">
                        <h3>The MRT Manufacturing Difference </h3>
                        <ul>
                            <li>
                            Australian owned and operated offshore manufacturing
                            facility allowing MRT to offer the most competitive
                            pricing in the market whilst delivering the highest
                            standard in the industry
                            </li>
                            <li>
                            5052 Marine Grade Aluminium: Light weight, Heavy duty
                            and corrosion resistant
                            </li>
                            <li>
                            Tig Welded by highly skilled and experienced welders
                            who specialise in Aluminium welding
                            </li>
                            <li>
                            Innovative Designs engineered for the harshest of
                            Australian conditions
                            </li>
                            <li>
                            Manufactured using state of the art cnc controlled
                            machinery
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="mrt-col col-50 primary">
                    <div className="image-holder"></div>
                </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default UteCanopyFeature;