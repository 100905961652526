import distanceIcon from '../../media/distance-icon.png';
import driveIcon from '../../media/drive-icon.png';
import terrainIcon from '../../media/terrain-icon.png';
import '../../css/adventures/AdventureInfo.css';


const AdventureInfo = ({ props }) => {
    const distance = props.distance;
    const distance_description = props.distance_description;
    const time_to_drive = props.time_to_drive;
    const time_to_drive_description = props.time_to_drive_description;
    const terrain = props.terrain;
    const terrain_description = props.terrain_description;

    return (
        <div id="section-info" className="anchored">
        <div id="section-info-anchor" className="anchor"></div>
        <div className="container wider">
            <div className="row">
                <div className="col col-30">
                    <div className="info-holder bg-clear">
                    <div className="icon">
                        <img src={distanceIcon} alt="distance icon" draggable="false"/>
                    </div>
                    <div className="info">
                        <h6 className="title">Distance: <b>{distance}</b></h6>
                        <p className="desc ellipsis" data-fulltext={distance_description}>{distance_description}</p>
                    </div>
                    </div>
                </div>
                <div className="col col-30">
                    <div className="info-holder bg-clear">
                    <div className="icon">
                        <img src={driveIcon} alt="time to drive icon" draggable="false"/>
                    </div>
                    <div className="info">
                        <h6 className="title">Time to drive: <b>{time_to_drive}</b></h6>
                        <p className="desc ellipsis" data-fulltext={time_to_drive_description}>{time_to_drive_description}</p>
                    </div>
                    </div>
                </div>
                <div className="col col-40">
                    <div className="info-holder bg-clear">
                    <div className="icon">
                        <img src={terrainIcon} alt="terrain icon" draggable="false"/>
                    </div>
                    <div className="info">
                        <h6 className="title">Terrain: <b>{terrain}</b></h6>
                        <p className="desc ellipsis" data-fulltext={terrain_description}>{terrain_description}</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default AdventureInfo;