import React from 'react'

const CanopyFeature = () => {
    return (
      <>
        <ul class="list">
          <li class="list-item">
            <span>2.5mm 5052 Marine Grade Aluminium</span>
          </li>
          <li class="list-item">
            <span>
              Internal 60x30x2.5mm RHS bracing allowing up to 300kg of static
              weight and 150kg of dynamic weight on the canopy roof
            </span>
          </li>
          <li class="list-item">
            <span>Fully Seam Welded</span>
          </li>
          <li class="list-item">
            <span>Full-Length Piano-type Stainless Steel Hinges</span>
          </li>
          <li class="list-item">
            <span>Stainless Steel T-Locks</span>
          </li>
          <li class="list-item">
            <span>Stainless Steel Striker Plate </span>
          </li>
          <li class="list-item">
            <span>Extra-Thick Rubber Dust Seal </span>
          </li>
          <li class="list-item">
            <span>Water Deflector Seal </span>
          </li>
        </ul>
      </>
    );
};

export default CanopyFeature;

