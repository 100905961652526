import Header from "../sections/header";
import NextSteps from '../sections/nextsteps';
import Footer from "../sections/footer";
import ToolboxUppericons from "../sections/aluminium-toolboxes/toolboxUppericons";
import ToolBoxesBanner from "../sections/aluminium-toolboxes/ToolBoxesBanner";
import ToolBoxesUndertray from '../sections/aluminium-toolboxes/ToolBoxesUndertray';
import ToolBoxeChestStyle from '../sections/aluminium-toolboxes/ToolBoxesChestStyle';
import ToolBoxesCrossDeck from '../sections/aluminium-toolboxes/ToolBoxesCrossDeck';
import ToolBoxesGenerator from "../sections/aluminium-toolboxes/ToolBoxesGenerator";
import ToolBoxesRectangularPlain from "../sections/aluminium-toolboxes/ToolBoxesRectangularPlain";
import ToolBoxesGullwing from "../sections/aluminium-toolboxes/ToolBoxesGullwing";
import ToolBoxesThreeQuarter from "../sections/aluminium-toolboxes/ToolBoxesThreeQuarter";
import ToolBoxesFullOpening from "../sections/aluminium-toolboxes/ToolBoxesFullOpening";
import ToolboxesDrawbar from "../sections/aluminium-toolboxes/ToolboxesDrawbar";
import UteToolboxesBuilttoLast from "../sections/aluminium-toolboxes/UteToolboxesBuilttoLast";
import UteToolboxesforWork from "../sections/aluminium-toolboxes/UteToolboxesforWork";
import ReliableTimeSaving from "../sections/aluminium-toolboxes/ReliableTimeSaving";
import useFetch from "../../useFetch";
import { Helmet } from "react-helmet-async";


const ToolBoxes = ({toolboxesProducts}) => {

	const { data, ispending, error } = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/pages/238');
	return (
		<>
			<Header title="Ute Toolboxes - Market Leader - Brisbane - Sydney - Perth - Gold Coast" />
			<div id="main" className="site-main page__top-shadow visible">
				<div id="main-content" className="main-content">
					{error && <div>{error}</div>}
					{error && <div></div>}
					{ispending && <div></div>}
					{data &&
						<>
							<Helmet meta={[
								{ name: 'description', content: data.yoast_head_json.description },
								{ name: 'robots', content: Object.keys(data.yoast_head_json.robots).map(key => `${key}: ${data.yoast_head_json.robots[key]}`).join(', ') },
								{ name: 'canonical', content: data.yoast_head_json.canonical },
								{ property: 'og:title', content: data.yoast_head_json.og_title },
								{ property: 'og:locale', content: data.yoast_head_json.og_locale },
								{ property: 'og:type', content: data.yoast_head_json.og_type },
								{ property: 'og:url', content: data.yoast_head_json.og_url },
								{ property: 'og:site_name', content: data.yoast_head_json.og_site_name },
								{ property: 'article:publisher', content: data.yoast_head_json.article_publisher },
								{ property: 'article:modified_time', content: data.yoast_head_json.article_modified_time },
								{ property: 'og:image', content: data.yoast_head_json.og_image[0].url },
								{ property: 'og:image:width', content: data.yoast_head_json.og_image[0].width },
								{ property: 'og:image:height', content: data.yoast_head_json.og_image[0].height },
								{ property: 'og:description', content: data.yoast_head_json.og_description },
								{ property: 'twitter:card', content: data.yoast_head_json.twitter_card },
							]}
							title={data.yoast_head_json.title }/>
							<ToolboxUppericons props={data.content.rendered} />
						</>
					}
					<ToolBoxesBanner />
					<ToolBoxesCrossDeck />
					<ToolBoxesUndertray />
					<ToolBoxesGenerator />
					<ToolBoxesRectangularPlain />
					<ToolBoxeChestStyle />
					<ToolBoxesGullwing />
					<ToolBoxesThreeQuarter />
					<ToolBoxesFullOpening />
					<ToolboxesDrawbar />
					<UteToolboxesBuilttoLast />
					<UteToolboxesforWork />
					<ReliableTimeSaving />
					<NextSteps />
					<Footer />
				</div>
			</div>
		</>
	);
};

export default ToolBoxes;
