import React from 'react'
import '../../css/uppericonsCss.css';
import parttrayicons from '../../media/uppericons/upper-icon-pt-final.png';
import dualcabicons from '../../media/uppericons/upper-icon-dc-final.png';
import extracabicons from '../../media/uppericons/upper-icon-ec-final.png';
import singlecabicons from '../../media/uppericons/upper-icon-sc-final.png';

const CanopyUppericons = () => {
    return(
        <>
        <div id="section-canopy-uppericons" className="">
            <div className="container">
                <div className="uppericons-wrapper">
                <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-part-tray-canopy/" className="uppericon">
                    <img src={parttrayicons} alt="MRT part tray ute canopy" />
                    <p>PART TRAY UTE CANOPY</p>
                </a>
                <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-dual-cab-canopy/" className="uppericon">
                    <img src={dualcabicons} alt="MRT dual cab ute canopy" />
                    <p>DUAL CAB UTE CANOPY</p>
                </a>
                <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-extra-cab-canopy/" className="uppericon">
                    <img src={extracabicons} alt="MRT extra cab ute canopy" />
                    <p>EXTRA CAB UTE CANOPY</p>
                </a>
                <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-single-cab-canopy/" className="uppericon">
                    <img src={singlecabicons} alt="MRT single cab ute canopy" />
                    <p>SINGLE CAB UTE CANOPY</p>
                </a>
                </div>
            </div>
        </div>
        </>
    )
}

export default CanopyUppericons;