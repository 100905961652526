

const TopArea = () => {
    return (
        <div
            id="top-area"
            className="top-area top-area-style-default top-area-alignment-right">
            <div className="container">
                <div className="top-area-items inline-inside">
                    <div className="top-area-block top-area-menu">
                        <nav id="top-area-menu">
                            <ul
                                id="top-area-navigation"
                                className="nav-menu styled inline-inside">
                                <li
                                    id="menu-item-14154"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-14154">
                                    <a href="https://www.mrt.com.au/faqs/">FAQs</a>
                                </li>
                                <li
                                    id="menu-item-7842"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-7842">
                                    <a href="https://www.mrt.com.au/blog/">Blog</a>
                                </li>
                                <li
                                    id="menu-item-16697"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16697">
                                    <a href="https://www.mrt.com.au/careers/">Careers</a>
                                </li>
                                <li
                                    id="menu-item-16699"
                                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-16699">
                                    <a href="https://www.mrt.com.au/wholesale/">
                                        Wholesale
                                    </a>
                                </li>
                                <li
                                    id="menu-item-7241"
                                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-7241">
                                    <a href="tel:1300650090">
                                        <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                                        1300 650 090
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default TopArea;