import "../../css/aluminium-toolboxes/ToolBoxesLeftImage.css";
import MRT7 from "../../media/MRT7.png";

const ToolBoxeChestStyle = () => {
    return ( 
        <div className="section-mrt-blocks image-left-background dark">
            <div id="chest-style-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="image-holder">                               
                                    <img src={MRT7} className="video-image-replacement" alt="MRT7" />
                        </div>						
                    </div>
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">CHEST STYLE TOOLBOXES</h3>
                            <p className="mrt-desc">MRT chest style ute toolboxes are designed to be large and spacious - which makes them the perfect multipurpose toolbox ideal for storing oversized items. They are made from the highest quality aluminium checker plate with a completely sealed and lockable design that will help extend the life of your most valuable tools and equipment.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/chest-style-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default ToolBoxeChestStyle;
