import '../css/nine.css';
import where from '../media/WHERE-WILL-YOUR-MRT-GEAR-TAKE-YOU_.png';
import where_mobile from '../media/WHERE-WILL-YOUR-MRT-GEAR-TAKE-YOU_mobile.png';

const Nine = () => {
    return (
        <div id="section-nine" className="section anchored">
            <video autoPlay loop muted id="section-background" className="desktop">
                <source src="https://www.mrt.com.au/wp-content/uploads/2023/06/MYMRTGEAR-banner-compressed.mp4" type="video/mp4" />
            </video>
            <div id="section-nine-anchor" className="anchor"></div>
            <div className="container wider">
                <div className="row">
                    <div className="col col-100">
                        <div className="content-holder">
                            <h2 className="title">
                                <span className="hidden">Where will your MRT gear take you?</span>
                                <img src={where} alt="Where will your MRT gear take you?" className="desktop" draggable="false" />
                                <img src={where_mobile} alt="Where will your MRT gear take you?" className="mobile" draggable="false" />
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Nine;