import React, { useState } from 'react';
import '../css/MiniSearchBar.css';

const MiniSearchBar = ({ products }) => {
    const [searchTerm, setSearchTerm] = useState('');

    if (!products) return null;

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = searchTerm ? products.filter((item) => {
        return item.slug.toLowerCase().includes(searchTerm.toLowerCase());
    }) : products;

    const clearSearch = () => {
        setSearchTerm('');
    };
    return (
        <>
            <div className='searchbar-container'>
                <form action="https://www.mrt.com.au/" method='get' role='search'>
                    <div className="input-wrapper">
                        <input className='searchbar' type="text" placeholder="Search Product" value={searchTerm} onChange={handleSearch} />
                        {searchTerm.length > 0 &&
                            <div className="search-clear">
                                <i className="fa-regular fa-xmark" onClick={clearSearch}></i>
                            </div>
                        }
                    </div>
                    <div className="aws-search-btn aws-form-btn">
                        <span className="aws-search-btn_icon">
                            <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px">
                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                            </svg>
                        </span>
                    </div>
                </form>
            </div>
            {searchTerm.length > 0 &&
                <>
                    {filteredData.length > 0 &&
                        <div className="result">
                            <ul>
                                {filteredData.map((item) => (
                                    <li className='result_item' id={item.id} key={item.id}>
                                        <div className="result_link">
                                            <a href={item.permalink} className="result_link_top">{item.name}</a>
                                            <span className='result_image'>
                                                {item.images.length > 0 &&
                                                    <img src={`${item.images[0].src}`} alt={item.images[0].alt} />}
                                            </span>
                                            <span className="result_content">
                                                <span className="result_title">{item.name}</span>
                                                {/* <span className="result_stock in">In stock</span> */}
                                                {/* <span className="result_sku">SKU: MRT1</span> */}
                                                {item.price_html &&
                                                    <span className="result_price" dangerouslySetInnerHTML={{ __html: item.price_html }}></span>
                                                }
                                            </span>
                                        </div>
                                    </li>
                                ))}
                                <li className="result_item search_more">
                                    <a href="#">View all results</a>
                                </li>
                            </ul>
                        </div>
                    }
                    {filteredData.length === 0 && searchTerm.length > 0 &&
                        <div className="result">
                            <ul>
                                <li className="result_item no_result">Nothing found</li>
                            </ul>
                        </div>
                    }
                </>
            }
        </>
    );
}

export default MiniSearchBar;