import React from 'react'
import '../../css/ute-canopy/UtecanopyBanner.css';
import '../../css/ute-canopy/Utecanopy.css'

const UtecanopyBanner = () => {
    return (
        <>
        <div id="banner" className="section-mrt-blocks anchored">
            <div id="banner-anchor" className="anchor"></div>
            <div id="top-nav">
                <div className="container">
                    <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <div className="menu-list-holder">
                        <ul className="menu-list">
                            <li className="menu-item"><a href="#features-anchor">FEATURES</a></li>
                            <li className="menu-item"><a href="#options-anchor">OPTIONS</a></li>
                            <li className="menu-item"><a href="#gallery-anchor">GALLERY</a></li>
                            <li className="menu-item"><a href="https://www.mrt.com.au/aluminium-ute-canopies/speak-with-an-expert/"><span className="hvr-ripple-out">SPEAK WITH AN EXPERT</span></a></li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="mrt-row">
                    <div className="mrt-col col-60">
                    <div className="info-holder bg-clear">
                        <h1>The MRT Ute Canopy</h1>
                        <h4>SELECT YOUR SIZE TO START BUILDING NOW</h4>

                        <div className="icons-holder">
                        <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-part-tray-canopy/" className="icon">
                            <img src="https://www.mrt.com.au/wp-content/uploads/2022/08/PART-TRAY-1201.png" alt="MRT part tray ute canopy silver checker plate" />
                            <p>PART TRAY</p>
                        </a>

                        <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-dual-cab-canopy/" className="icon">
                            <img src="https://www.mrt.com.au/wp-content/uploads/2022/08/DUAL-CAB-1801.png" alt="MRT dual cab ute canopy silver checker plate" />
                            <p>DUAL CAB</p>
                        </a>

                        <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-extra-cab-canopy/" className="icon">
                            <img src="https://www.mrt.com.au/wp-content/uploads/2022/08/EXTRA-CAB-2101.png" alt="MRT extra cab ute canopy silver checker plate" />
                            <p>EXTRA CAB</p>
                        </a>

                        <a href="https://www.mrt.com.au/product/aluminium-ute-canopies-single-cab-canopy/" className="icon">
                            <img src="https://www.mrt.com.au/wp-content/uploads/2022/08/SINGLE-CAB-2401.png" alt="MRT single cab ute canopy silver checker plate" />
                            <p>SINGLE CAB</p>
                        </a>
                        </div>
                    </div>
                    </div>
                    <div className="mrt-col col-40"></div>
                </div>
            </div>
        </div>
        </>
    );
}
export default UtecanopyBanner;