import '../css/four.css';
import React from 'react'

const Four = ({icon_roof_racks, green_arrow }) => {
    return (
        <div id="section-chassis-mount-for-initiator" className="section-mrt-blocks anchored">
            <div id="section-chassis-mount-for-initiator-anchor" className="anchor"></div>
            <div className="container boxed">
                <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <h1><img src={icon_roof_racks} alt="x-series-logo" /><br />CHASSIS-MOUNT CANOPY<span className="cust-text-header"> PACKAGE</span></h1>
                    </div>
                </div>
                <div className="mrt-row">
                    <div className="mrt-col col-60">
                        <div className="list-holder">
                            <div className="mrt-row row-reversed">
                                <div className="mrt-col col-50">
                                    <ul className="list">
                                        <span>2-DOOR CANOPY</span>
                                        <li className="item li-head">1650 or 1800x1800 X-Series Chassis Mount Canopy</li>
                                        <li className="green-caps desktop"><a href="https://www.mrt.com.au/xseries-canopy-page-without-app/">Learn more <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="item">2-Doors</li>
                                        <li className="item">Powdercoat Finish</li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Undertray Toolboxes</li>
                                        <li className="item">Flared Mudguards </li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Premium Convenience Package</li>
                                        <li className="item">
                                            <ul>
                                                <li className="item">Keyless Entry System - Canopy</li>
                                                <li className="item">Internal LED Lighting</li>
                                                <li className="item">Anderson Out</li>
                                            </ul>
                                        </li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$14,151</s></span></p>
                                    <p className="current-price">$12,999</p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/chassis-mount-2-door-initiator-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                                <div className="mrt-col col-50">
                                    <ul className="list">
                                        <span>3-DOOR CANOPY</span>
                                        <li className="item li-head">1650 or 1800x1800 X-Series Chassis Mount Canopy</li>
                                        <li className="green-caps desktop"><a href="https://www.mrt.com.au/xseries-canopy-page-without-app/">Learn more <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="item">3-Doors</li>
                                        <li className="item">Powdercoat Finish</li>
                                        <li className="green-caps desktop"><a href="#section-finishes-anchor">View powder coat colours <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="green-caps mobile"><a href="#section-finishes-mobile-anchor">View powder coat colours <img src={green_arrow} alt="green arrow" /></a></li>
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Undertray Toolboxes</li>
                                        <li className="item">Flared Mudguards </li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Premium Convenience Package</li>
                                        <li className="item">
                                            <ul>
                                                <li className="item">Keyless Entry System - Canopy</li>
                                                <li className="item">Internal LED Lighting</li>
                                                <li className="item">Anderson Out</li>
                                            </ul>
                                        </li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$14,451</s></span></p>
                                    <p className="current-price">$13,299</p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/chassis-mount-3-door-initiator-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-40">
                        <div className="img-holder">
                            <video playsInline autoPlay loop muted id="powder-coat-video">
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/08/Chassis-mount-Initiator.mp4" type="video/mp4" />
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/08/Chassis-mount-Initiator.webm" type="video/webm" />
                                <source src="undefined" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Four;
