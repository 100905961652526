import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import React, { useState, useEffect } from 'react';
import Home from './components/pages/homepage';
import Adventures from './components/pages/adventures';
import UteCanopy from './components/pages/utecanopy';
import Post from './components/pages/postview';
import ToolBoxes from "./components/pages/ToolBoxes";
import Packages from './components/pages/packages';
import Accessories from './components/pages/accessories';


function App() {

	
    const key = 'ck_15605a753310147dbe93c8dcf4052c1c937e8fae';
    const secret = 'cs_01c26c4d7aa29c8919dc3fa1c91230c28b1478c5';
    const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [toolboxesProducts, setToolboxesProducts] = useState([]);
    const [accessoriesProducts, setAccessoriesProducts] = useState ([]);

    useEffect(() => {
const fetchData = async () => {
    try {
        const response = await fetch(
            `https://mrt.abovedigital.co/wp-json/wc/v3/products?consumer_key=${key}&consumer_secret=${secret}`
        );
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const totalPages = response.headers.get('X-WP-TotalPages');
        const fetchPromises = [];

        for (let page = 1; page <= totalPages; page++) {
            fetchPromises.push(
                fetch(
                    `https://mrt.abovedigital.co/wp-json/wc/v3/products?consumer_key=${key}&consumer_secret=${secret}&page=${page}`
                ).then(response => response.json())
            );
        }

        const allProducts = await Promise.all(fetchPromises);
        const mergedProducts = allProducts.flat();
        setProducts(mergedProducts);

        const toolboxes = mergedProducts.filter((product) => {
            return product.categories.some((category) => category.id === 58);
        });
        setToolboxesProducts(toolboxes);

        const accessories = mergedProducts.filter((product) => {
            const categories = product.categories.map(cat => cat.name);
            const allowedCategories = [
              "Jerry Can Holders",
              "X-series Adjustable Shelving and Drawer Units",
              "Keyless Entry Systems",
              "X-Series Drawer Modules",
              "Fitting Kits",
              "Flooring",
              "Fridge Slides",
              "Dog Box Accessories"
            ];
            return categories.some(category => allowedCategories.includes(category));
          });
          setAccessoriesProducts(accessories);
          

        setLoading(false);

    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};

fetchData();
}, []);

	return (
		<HelmetProvider>
			<Router>
				<div className="App">
					<Routes>
						<Route path="/" element={<Home products={products} products_loading={loading}/>} />
						<Route path="/toolboxes" element={<ToolBoxes toolboxesProducts={toolboxesProducts}/>} />
						<Route path="/aluminium-ute-canopies" element={<UteCanopy />} />
						<Route path="/mrt-adventures/old-tele-track/" element={<Adventures />} />
						<Route path="/post/:id" element={<Post />} />
						<Route path="/packages" element={<Packages />} />
						<Route path="/accessories" element={<Accessories accessoriesProducts={accessoriesProducts}/>} />
					</Routes>
				</div>	
			</Router>
		</HelmetProvider>
	);
}

export default App;




