
import '../css/footer.css';
import MRTlogo from '../media/mrt-logo-1.png';
import IconFb from '../media/icon-fb.png';
import IconIg from '../media/icon-ig.png';
import IconYt from '../media/icon-yt.png';
import GC from '../media/GC-feature.png';


const Footer = () => {
  return (
    <footer id="mrt-footer" className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <img loading="lazy" src={MRTlogo} alt="MRT Logo" style={{ marginBottom: '25px' }} />
            <p className="footer-desc">@ Copyright 2024 MRT | All rights reserved | Designed and managed by <a href="https://abovedigital.co/">Above Digital</a></p>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-title"> JOIN OUR COMMUNITY</h4>
            <p className="footer-desc">The MRT community is full of useful information, advice, tips, informative videos and industry related blogs and articles!</p>
            <div className="mrt-social-media-container">
              <a href="https://www.facebook.com/mrtaustralia/" className="mrt-social-media" target="_blank" rel="noopener noreferrer"><img loading="lazy" src={IconFb} alt="mrt facebook" /></a>
              <a href="https://www.instagram.com/mrt_australia/" className="mrt-social-media" target="_blank" rel="noopener noreferrer"><img loading="lazy" src={IconIg} alt="mrt instagram" /></a>
              <a href="https://www.youtube.com/channel/UCIfyriLiauAWT10mqpD3Qkg" className="mrt-social-media" target="_blank" rel="noopener noreferrer"><img loading="lazy" src={IconYt} alt="mrt youtube" /></a>
            </div>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-title">USEFUL INFORMATION</h4>
            <ul id="mrt-footer-menu" className="menu">
              <li className="menu-item"><a href="https://www.mrt.com.au/faqs/">FAQs</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/blog/">Blog</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/careers/">Careers</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/mymrtgear/">MRT Gallery</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/aluminium-toolboxes/">Toolboxes</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/aluminium-ute-canopies/">UTE Canopies</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/aluminium-ute-trays/">UTE Trays</a></li>
              <li className="menu-item"><a href="https://www.mrt.com.au/wp-content/themes/mrt/pdf/privacy-policy.pdf">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="col-sm-3">
            <h4 className="footer-title">MRT GIFT CARDS NOW AVAILABLE!</h4>
            <img loading="lazy" src={GC} alt="MRT Gift Card" />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
