import '../../css/aluminium-toolboxes/ToolBoxesBanner.css';
import TbBanner from '../../media/toolboxesbannerimage.png';

const ToolBoxesBanner = () => {
  return (
    <div className="section-toolboxes-banner section-mrt-blocks aluminium-toolboxes">
      <div className="container">
        <div className="mrt-row">
          <div className="mrt-col col-50">
            <div className="info-holder bg-clear">
              <h3 className="mrt-subtitle">EXTENSIVE RANGE OF SPECIALTY</h3>
              <h1 className="mrt-title">ALUMINIUM UTE<span className="bigger">TOOLBOXES</span></h1>
              <p className="mrt-desc">
                MRT has over 45 different models of toolboxes available in 13 varying styles which include - standard underbody toolboxes, 4WD underbody toolboxes, full opening toolboxes, full opening toolboxes with drawers, generator toolboxes, gullwing toolboxes, rectangle toolboxes, square opening toolboxes, 3/4 opening toolboxes and 3/4 opening toolboxes with drawers.
              </p>
            </div>
          </div>
          <div className="mrt-col col-50">
            <div className="image-holder">
              <img src={TbBanner} alt="MRT toolboxes banner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolBoxesBanner;
