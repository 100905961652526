import '../css/eight.css';
import gallery1 from '../media/mrt-gallery-1.jpg';
import gallery2 from '../media/mrt-gallery-2.png';
import gallery3 from '../media/mrt-gallery-3.jpg';
import gallery4 from '../media/mrt-gallery-4.jpg';
import gallery5 from '../media/mrt-gallery-5.jpg';


const Eight = () => {
    return (
        <div id="section-eight" className="section anchored">
            <div id="section-eight-anchor" className="anchor"></div>
            <div className="container wide">
                <div className="row">
                    <div className="col col-100">
                        <div className="gallery-holder">
                            <div className="gallery">
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank">
                                    <img src={gallery1} alt="MRT gallery image 1"/>
                                </a>
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank">
                                    <img src={gallery2} alt="MRT gallery image 2"/>
                                </a>
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank" className="desktop">
                                    <img src={gallery3} alt="MRT gallery image 3"/>
                                </a>
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank">
                                    <img src={gallery4} alt="MRT gallery image 4"/>
                                </a>
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank" className="desktop">
                                    <img src={gallery5} alt="MRT gallery image 5"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-100">
                        <div className="content-holder">
                            <h2 className="title">FOLLOW MRT</h2>
                            <div className="socials">
                                <a href="https://www.facebook.com/mrtaustralia/" target="_blank">
                                    <i className="fa-brands fa-facebook-f" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.pinterest.ph/mrtaustralia/" target="_blank">
                                    <i className="fa-brands fa-pinterest-p" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.instagram.com/mrt_australia/" target="_blank">
                                    <i className="fa-brands fa-instagram" aria-hidden="true"></i>
                                </a>
                                <a href="https://www.youtube.com/@mrt_australia" target="_blank">
                                    <i className="fa-brands fa-youtube" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Eight;