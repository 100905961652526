import "../../css/aluminium-toolboxes/ToolBoxesLeftImage.css";
import MRT19 from "../../media/MRT19.png";


const ToolboxesDrawbar = () => {
    return ( 
        <div className="section-mrt-blocks image-left-background dark">
            <div id="drawbar-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="image-holder">                               
                                    <img src={MRT19} className="video-image-replacement" alt="MRT7" />
                        </div>						
                    </div>
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">DRAWBAR TOOLBOXES</h3>
                            <p className="mrt-desc">MRT drawbar toolboxes have a universal design perfectly shaped to suit your drawbar. If you need durability and reliability while onsite or in transit - these toolboxes are the most cost-effective yet highest quality design on the market. They are fully sealed, extra strong and completely lockable for peace of mind.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/drawbar-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ToolboxesDrawbar;