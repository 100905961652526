import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import MRT1 from "../../media/MRT1.png";

const ToolBoxesUndertray = () => {
    return (
        <div className="section-mrt-blocks image-right-background ">
            <div id="undertray-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth text-align-right" >
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">UNDERTRAY TOOLBOXES</h3>
                            <p className="mrt-desc">MRT undertray ute toolboxes are highly durable with an extra-strong, fully-welded design - that will stand the test of time even in the harshest working conditions. These resilient designed toolboxes are lockable and weatherproof with a spacious interior to suit the individual storage needs of any professional or DIY tradesperson.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/undertray-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={MRT1} alt="MRT1" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolBoxesUndertray;
