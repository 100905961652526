import "../../css/aluminium-toolboxes/ToolBoxesVideoLeft.css";
import PlayButton from "../../media/play-button.png";
import GeneratorVideoCover from "../../media/generator-toolbox-video-cover.png";

const ToolBoxesGenerator = () => {
  return (
    <div className="section-mrt-blocks video-left dark">
      <div id="generetor-toolboxes" className="anchor-holder"></div>
      <div className="container fullwidth">
        <div className="mrt-row">
          <div className="mrt-col col-50">
            <div className="video-holder" style={{backgroundImage: `url(${GeneratorVideoCover})`}}>
              <div className="video">
                <div className="video-wrapper">
                  <a href="#video-generetor-toolboxes" className="video-image" rel="wp-video-lightbox">
                    <img src={PlayButton} alt="Play Button" />
                  </a>
                  <div id="video-generetor-toolboxes" style={{ display: "none" }}>
                    <iframe title="Generator Toolboxes Video" width="100%" height="315" src="https://www.youtube.com/embed/bEDEO68g6nw" frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mrt-col col-50">
            <div className="info-holder">
              <p className="mrt-subtitle"></p>
              <h3 className="mrt-title">GENERATOR TOOLBOXES</h3>
              <p className="mrt-desc">MRT generator ute toolboxes are ideal for housing your generator in your ute canopy, ute tray, caravan or camper. They are specially designed to store and protect your generator with a smart, weather and dust proof design that is key lockable and lightweight - yet extremely robust with marine grade aluminium checker plate.</p>
              <div className="animation-holder">
                <a href="https://www.mrt.com.au/aluminium-toolboxes/generator-toolboxes/">
                  <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolBoxesGenerator;
