import "../../css/landing/BiglapBanner.css";
import MRTxB from "../../media/mrt-x-borderland.png";
import Xseries from "../../media/x-series-logo-white-1.png";
import GreenArrow from "../../media/green-arrow.png";

const BiglapBanner = () => {
    return (  
        <section id="bg-banner">
            <div className="five margin-top">
                <div className="slide-holder">
                    <div className="container wider">
                        <div className="row">
                            <div className="col col-50 cust">
                                <div className="content-holder">
                                    <div className="logo-holder">
                                        <img loading="lazy" src={MRTxB} alt="MRT x Borderland Explorers Logo" />
                                    </div>
                                    <div className="text-holder">
                                        <h1>‘The Big Lap’</h1>
                                        <span>AN <img src={Xseries} alt="X-Series Logo" /> ADVENTURE</span>
                                        <a href="https://www.mrt.com.au/borderlandexplorers/">
                                            <span className="learn-more">Learn more <img style={{margin: '0 8px'}} src={GreenArrow} alt="green arrow icon" /></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-50">
                                <div className="video-container">
                                    <video autoPlay loop muted playsInline src="https://www.mrt.com.au/wp-content/uploads/2023/10/The-Big-Lap-top-header-with-fade-1.mp4"></video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BiglapBanner;
