
import "../../css/landing/AluminiumUteCanopies.css";
import MRTBlackToolbox from "../../media/MRT_Black_PC_Canopy__Toolboxes-Corner-View-1.png";
const AluminiumUteCanopies = () => {
    return (
        <div id="section-2" className="section anchored">
            <div id="section-2-anchor" className="anchor"></div>
            <div className="container wider">
                <div className="row">
                    <div className="col col-60">
                        <div className="img-holder">
                            <img src={MRTBlackToolbox} />
                        </div>
                    </div>
                    <div className="col col-40">
                        <div className="content-holder">
                            <h2 className="title">Aluminium Ute Canopies</h2>
                            <p className="desc">MRT canopies offer a high level of durability without compromising affordability. Available in standard sizes including part tray, dual cab, extra cab, and single cab, customisation options are also available to cater to specific width and length requirements. MRT canopies also offer a range of options and upgrades, including individual ladder racks, full roof racks, spare wheel carriers, jerry can holders, fold down ladders, various window options, keyless entry systems, lift-off systems, and more, ensuring that you have access to a comprehensive suite of features to meet your specific needs.&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AluminiumUteCanopies;
