import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import MRT20 from "../../media/MRT20.png";



const ToolBoxesFullOpening = () => {
    return ( 
        <div className="section-mrt-blocks image-right-background default">
            <div id="full-opening-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth text-align-right">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">FULL OPENING TOOLBOXES</h3>
                            <p className="mrt-desc">MRT full opening ute toolboxes offer a spacious design that can house large and cumbersome equipment with ease - both onsite and in transit. Made from 2.5mm aluminium checker plate and fully weatherproof, these toolboxes are reliable and robust enough to keep your most valuable items safe for years to come.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/full-opening-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={MRT20} alt="MRT Full Opening Toolbox" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolBoxesFullOpening;
