import '../css/two.css';

const Two = () => {
    return (
        <div id="section-two" className="section-mrt-blocks anchored">
            <div id="section-two-anchor" className="anchor"></div>
            <div className="container boxed">
                <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <h1>CHECKERPLATE &amp; FLAT ALLOY <span className="cust-text-header">PACKAGES</span></h1>
                    </div>
                </div>
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="img-holder">
                            <video playsInline autoPlay loop muted id="powder-coat-video">
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/05/checkerplate-initiator-1.mp4" type="video/mp4" />
                                <source src="https://www.mrt.com.au/wp-content/uploads/2023/05/0001-0250.webm" type="video/webm" />
                                <source src="undefined" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="list-holder">
                            <div className="mrt-row">
                                <div className="mrt-col col-50">
                                    <span className="powdercoated-text">CheckerPLATE OR Flat Alloy finish</span>
                                    <ul className="list above-list">
                                        <li><span>TRAY</span></li>
                                        <li className="item li-head">1650 or 1800x1800 Aluminium Flat-bed Ute Tray</li>
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Under Tray Toolboxes</li>
                                        <li className="item">Flared Mudguards</li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind Spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model</li>
                                    </ul>
                                    <ul className="list">
                                        <li><span>CANOPY</span></li>
                                        <li className="item li-head">1650 or 1800mm Long x 1800mm wide x 860mm high Aluminium Ute Canopy</li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Keyless Entry System</li>
                                        <li className="item">All installation and fitting charges</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$9,164</s></span></p>
                                    <p className="current-price">$8,499</p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/initiator-package-checkerplate-alloy-finish-order-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                                <div className="mrt-col col-50">
                                    <span className="powdercoated-text">CheckerPLATE OR Flat Alloy finish + LIFT-OFF</span>
                                    <ul className="list above-list">
                                        <li><span>TRAY</span></li>
                                        <li className="item li-head">1650 or 1800x1800 Aluminium Flat-bed Ute Tray</li>
                                        <li className="item">LED Tail Lights - 2 options available</li>
                                        <li className="item">Under Tray Toolboxes</li>
                                        <li className="item">Flared Mudguards</li>
                                        <li className="item">All installation and fitting charges - please note if your vehicle has Blind Spot monitoring/lane assist the relocation charge for this is additional and varies depending on make and model</li>
                                    </ul>
                                    <ul className="list">
                                        <li><span>CANOPY</span></li>
                                        <li className="item li-head">1650 or 1800mm Long x 1800mm wide x 860mm high Aluminium Ute Canopy</li>
                                        <li className="item">3 x X-series Ladder Racks or T-channel Rails</li>
                                        <li className="item">Keyless Entry System</li>
                                        <li className="item">Lift-off System</li>
                                        <li className="item">All installation and fitting charges</li>
                                    </ul>
                                    <p className="from-price">From <span><s>$10,481</s></span></p>
                                    <p className="current-price">$9,499</p>
                                    <div className="button-holder">
                                        <a href="https://www.mrt.com.au/initiator-package-checkerplate-alloy-finish-with-lift-off-order-form/" className="button">ORDER NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Two;
