import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import MRT9 from "../../media/MRT9.png";

const ToolBoxesRectangularPlain = () => {
    return ( 
        <div className="section-mrt-blocks image-right-background default">
            <div id="rectangular-plain-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth text-align-right" >
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">RECTANGULAR PLAIN TOOLBOXES</h3>
                            <p className="mrt-desc">MRT rectangular plain ute toolboxes are a classic and robust design that is a favourite of many tradespeople - as they offer adequate space to house their oversized tools with ease. Made from heavy-duty 2.5 aluminium checker plate, these are weatherproof, seam welded and fitted with full length piano hinges and robust T-locks for added security.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/rectangular-plain-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={MRT9} alt="MRT Toolbox" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolBoxesRectangularPlain;
