import React from "react";
import "../css/banner.css";

const Banner = ({ title_img }) => {
  return (
    <div className="section-banner" id="section-mrt-blocks anchored">
      <div id="section-banner-background">
        <video
          id="bgvid"
          preload="auto"
          playsInline
          autoPlay
          loop
          muted
          data-desktop-vid="https://www.mrt.com.au/wp-content/uploads/2023/06/tradesman-desktop-banner.mp4"
          data-mobile-vid="https://www.mrt.com.au/wp-content/uploads/2023/06/tradesman-mobile-banner.mp4"
        >
          <source
            src="https://www.mrt.com.au/wp-content/uploads/2023/06/tradesman-desktop-banner.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div id="section-banner-anchor" className="anchor"></div>
      <div className="container boxed">
        <div className="mrt-row">
          <div className="mrt-col col-cust-1">
            <div className="info-holder bg-clear">
              <h1 className="title">
                <span className="hidden">The Tradesman Package</span>
                <img
                  src={title_img}
                  alt="The Tradesman Package"
                  draggable="false"
                />
              </h1>
              <h3 className="subtitle">SAVE UP TO $1,900!</h3>
              <div className="button-holder">
                <a href="#section-one-anchor">
                  <span className="btn-primary">VIEW PACKAGES</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
