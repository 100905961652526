import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import MRT10 from "../../media/MRT10.png";

const ToolBoxesGullwing = () => {
    return ( 
        <div className="section-mrt-blocks image-right-background default">
            <div id="gullwing-toolboxes" className="anchor-holder"></div>
            <div className="container fullwidth text-align-right">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">GULLWING TOOLBOXES</h3>
                            <p className="mrt-desc">MRT gullwing ute toolboxes provide the best protection for your tools and equipment, when you need it most. Made from heavy-duty 2.5mm aluminium checker plate - they are all fully seam welded and fitted with weather resistant seals. This lockable and lightweight design will stand the test of time and is ideal for transporting your most valuable equipment.</p>
                            <div className="animation-holder">
                                <a href="https://www.mrt.com.au/aluminium-toolboxes/gullwing-toolboxes/">
                                    <span className="hvr-ripple-out">VIEW ENTIRE RANGE</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={MRT10} alt="MRT Gullwing Toolbox" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ToolBoxesGullwing;
