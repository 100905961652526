import '../css/one.css';

const One = ({props}) => {
    const main_img = props.main_img;
    const data = props.data;
    const data1 = data.slice(0, data.length / 2);
    const data2 = data.slice(data.length / 2, data.length);
    return (
        <div id="section-one" className="section-one anchored">
            <div id="section-one-anchor" className="anchor"></div>
            <div className="initiator-holder">
                <h2>YOUR ADVENTURE STARTS HERE</h2>
            </div>
            <div className="row content">
                <div className="switcher col col-75">
                    <div className="col col-33">
                        <div className="product-holder">
                            {data1.map((item, index) => {
                                return (
                                    <div key={index} className="product">
                                        <img src={item.icon} alt={item.title}/>
                                        <span className="text-holder">
                                            <span>{item.title}</span>
                                            <a href={item.link}>LEARN MORE</a>
                                        </span>
                                    </div>
                                )
                            }
                            )}
                        </div>
                    </div>
                    <div className=" col-67 desktop-col">
                        <div className="image-holder">
                            <img className="image-center" src={main_img} alt="initiator package ute canopy and tray showcase"/>
                        </div>
                    </div>
                </div>
                <div className="col col-25">
                    <div className="product-holder">
                        {data2.map((item, index) => {
                            return (
                                <div key={index} className="product">
                                    <img src={item.icon} alt={item.title}/>
                                    <span className="text-holder">
                                        <span>{item.title}</span>
                                        <a href={item.link}>LEARN MORE</a>
                                    </span>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default One;