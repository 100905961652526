import useFetch from "../../../useFetch";
import '../../css/adventures/AdventureBanner.css';
import mrt_logo from '../../media/mrt-logo-1.png';

const AdventureBanner = ({props}) => {
    const title = props.title;
    const {data: map_img, ispending: map_img_ispending, error: map_img_error} = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/media/'+props.map);
    return (
        <div id="section-banner" className="anchored">
            <div id="section-banner-anchor" className="anchor"></div>
            <div className="container wide">
                <div className="row">
                    <div className="col col-67">
                        <h1 className="title">{title}</h1>
                        <h2 className="subtitle">AN <img src={mrt_logo} alt="MRT logo"/> ADVENTURE</h2>
                    </div>
                    <div className="col col-33">
                        <div className="img-holder">
                            {map_img_error && <div>{map_img_error}</div>}
                            {map_img_ispending && <div className="adventure-banner-loading"></div>}
                            {map_img && 
                                <img src={map_img.source_url} alt={title+" map"} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdventureBanner;