import React from 'react'
import '../../css/ute-canopy/UtecanopyMatch.css'
import '../../css/ute-canopy/Utecanopy.css'
import '../../css/uppericonsCss.css'
const UtecanopyMatch = () => {
    return(
        <>
        <div id="match" className="section-mrt-blocks">
        <div id="match-anchor"></div>
            <div className="container fullwidth">
                <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <div className="title-holder">
                            <h2>MADE TO MATCH</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mrt-row gradient-bg-rev">
                <div className="mrt-col col-50 le">
                    <div className="left">
                        <div className="info-holder bg-clear">
                            <h2>MRT Complete Tray</h2>
                            <p>SELECT YOUR SIZE TO START BUILDING NOW</p>
                        </div>
                    </div>
                    <MatchIcons parentClass="le" />
                </div>
                <div className="mrt-col col-50 ri">
                    <div className="right">
                        <div className="info-holder bg-clear">
                            <h2>MRT Flatbed Ute Tray</h2>
                            <p>SELECT YOUR SIZE TO START BUILDING NOW</p>
                        </div>
                    </div>
                    <MatchIcons parentClass="ri" />
                </div>
            </div>
        </div>
        </>
    );
};

const MatchIcons = ({parentClass}) => {
    return (
      <>
        <div className="section-tray-uppericons">
          <div className="container">
            <div className="uppericons-wrapper">
              {parentClass === "le" ? (
                <>
                  <a
                    href="https://www.mrt.com.au/product/dual-cab-complete-tray-1650mmx1800mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-1650-x-1800.png"
                      alt="MRT Complete Tray dual cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      DUAL CAB <br /> 1650 x 1800mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/dual-cab-complete-tray-1650mmx1900mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-1650-x-1900.png"
                      alt="MRT Complete Tray dual cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      DUAL CAB <br /> 1650 x 1900mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/dual-cab-complete-tray-1800mmx1800mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-1800-x-1800.png"
                      alt="MRT Complete Tray dual cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      DUAL CAB <br /> 1800 x 1800mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/dual-cab-complete-tray-1800mmx1900mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-1800-x-1900.png"
                      alt="MRT Complete Tray dual cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      DUAL CAB <br /> 1800 x 1900mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/extra-cab-complete-tray-2100mmx1800mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-2100-x-1800.png"
                      alt="MRT Complete Tray extra cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      EXTRA CAB <br /> 2100 x 1800mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/extra-cab-complete-tray-2100mmx1900mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-2100-x-1900.png"
                      alt="MRT Complete Tray extra cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      EXTRA CAB <br /> 2100 x 1900mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/single-cab-complete-tray-2400mmx1800mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-2400-x-1800.png"
                      alt="MRT Complete Tray single cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      SINGLE CAB
                      <br /> 2400 x 1800mm{" "}
                    </p>
                  </a>
                  <a
                    href="https://www.mrt.com.au/product/single-cab-complete-tray-2400mmx1900mm/"
                    className="uppericon "
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/complete-tray-2400-x-1900-1.png"
                      alt="MRT Complete Tray single cab sizes ute canopy"
                    />
                    <p>
                      {" "}
                      SINGLE CAB
                      <br /> 2400 x 1900mm{" "}
                    </p>
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="https://www.mrt.com.au/product/dual-cab-tray-only-1650mmx1800mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-1650x1800-1.png"
                      alt="MRT Flatbed Ute Tray dual cab sizes ute canopy"
                    />
                    <p>
                      DUAL CAB <br />
                      1650 x 1800mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/dual-cab-tray-only-1650mmx1900mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-1650x1900-1.png"
                      alt="MRT Flatbed Ute Tray dual cab sizes ute canopy"
                    />
                    <p>
                      DUAL CAB <br />
                      1650 x 1900mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/dual-cab-tray-only-1800mmx1800mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-1800x1800-1.png"
                      alt="MRT Flatbed Ute Tray dual cab sizes ute canopy"
                    />
                    <p>
                      DUAL CAB <br />
                      1800 x 1800mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/dual-cab-tray-only-1800mmx1900mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-1800x1900-1.png"
                      alt="MRT Flatbed Ute Tray dual cab sizes ute canopy"
                    />
                    <p>
                      DUAL CAB <br />
                      1800 x 1900mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/extra-cab-tray-only-2100mmx1800mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-2100x1800-1.png"
                      alt="MRT Flatbed Ute Tray extra cab sizes ute canopy"
                    />
                    <p>
                      EXTRA CAB <br />
                      2100 x 1800mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/extra-cab-tray-only-2100mmx1900mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-2100x1900-1.png"
                      alt="MRT Flatbed Ute Tray extra cab sizes ute canopy"
                    />
                    <p>
                      EXTRA CAB <br />
                      2100 x 1900mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/single-cab-tray-only-2400mmx1800mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-2400x1800-1.png"
                      alt="MRT Flatbed Ute Tray single cab sizes ute canopy"
                    />
                    <p>
                      SINGLE CAB
                      <br />
                      2400 x 1800mm
                    </p>
                  </a>

                  <a
                    href="https://www.mrt.com.au/product/single-cab-tray-only-2400mmx1900mm/"
                    className="uppericon"
                  >
                    <img
                      className="tray-image"
                      src="https://www.mrt.com.au/wp-content/uploads/2022/09/tray-only-2400x1900-1.png"
                      alt="MRT Flatbed Ute Tray single cab sizes ute canopy"
                    />
                    <p>
                      SINGLE CAB
                      <br />
                      2400 x 1900mm
                    </p>
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );

};
export default UtecanopyMatch;