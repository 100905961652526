import Header from '../sections/header';
import useFetch from '../../useFetch';
import { Helmet } from 'react-helmet-async';
import BiglapBanner from '../sections/landing/BiglapBanner';
import Eight from '../sections/eight';
import Nine from '../sections/nine';
import PackageReview from '../sections/landing/PackageReview';
import ChassismountBanner from '../sections/landing/ChassismountBanner';
import AluminiumUteCanopies from '../sections/landing/AluminiumUteCanopies';
import AluminiumUteTrays from '../sections/landing/AluminiumUteTrays';	
import AluminiumUteToolboxes from '../sections/landing/AluminiumUteToolboxes';
import ManufacturingBanner from '../sections/landing/ManufacturingBanner';
import NextSteps from '../sections/nextsteps';
import PackageChoice from '../sections/PackageChoice';
import Footer from '../sections/footer';
import CategoryMenu from '../sections/categoryMenu';
import MrtAdventure from '../sections/landing/mrtAdventure';



const Homepage = ({products, products_loading}) => {
	const {data, ispending, error} = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/pages/19072');
	return (
    <>
		<Header title="MRT" products={products} products_loading={products_loading}/>
		<div id="main" className="site-main page__top-shadow visible">
			<div id="main-content" className="main-content">
				{error && <div>{error}</div>}
				{error && <div></div>}
				{ispending && <div></div>}
				{data && 
					<Helmet meta={[
						{name: 'description', content: data.yoast_head_json.description},
						{name: 'robots', content: Object.keys(data.yoast_head_json.robots).map(key => `${key}: ${data.yoast_head_json.robots[key]}`).join(', ')},
						{name: 'canonical', content: data.yoast_head_json.canonical},
						{property: 'og:title', content: data.yoast_head_json.og_title},
						{property: 'og:locale', content: data.yoast_head_json.og_locale},
						{property: 'og:type', content: data.yoast_head_json.og_type},
						{property: 'og:url', content: data.yoast_head_json.og_url},
						{property: 'og:site_name', content: data.yoast_head_json.og_site_name},
						{property: 'article:publisher', content: data.yoast_head_json.article_publisher},
						{property: 'article:modified_time', content: data.yoast_head_json.article_modified_time},
						{property: 'og:image', content: data.yoast_head_json.og_image[0].url},
						{property: 'og:image:width', content: data.yoast_head_json.og_image[0].width},
						{property: 'og:image:height', content: data.yoast_head_json.og_image[0].height},
						{property: 'og:description', content: data.yoast_head_json.og_description},
						{property: 'twitter:card', content: data.yoast_head_json.twitter_card},
					]}
					title={data.yoast_head_json.title}
					/>
				}
				<PackageChoice/>
				<CategoryMenu/>
				<BiglapBanner />
				<MrtAdventure/>
				<ChassismountBanner />
				<PackageReview />
				<AluminiumUteCanopies />
				<AluminiumUteTrays />
				<AluminiumUteToolboxes />
				<ManufacturingBanner />
				<Eight/>
				<Nine/>
				<NextSteps/>
				<Footer/>
			</div>
		</div>
    </>
	);
}

export default Homepage;
