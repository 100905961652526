import "../../css/landing/ChassismountBanner.css";
import Xseries from "../../media/x-series-logo-white-1.png";
import Chassismount from "../../media/CHASSIS-MOUNT-CANOPY-BANNER.png";


const ChassismountBanner = () => {
    return (
        <section id="cm-banner">
            <div className="four">
                <div className="slide-holder">
                    <div className="container wider">
                        <div className="mrt-row">
                            <div className="mrt-col col-100">
                                <div className="info-holder bg-clear">
                                    <h1>
                                        <img loading="lazy" className="x-series-logo" src={Xseries} alt="x-series-logo" /><br /> 
                                        <img loading="lazy" className="chassis-mount-stroke" src={Chassismount} alt="X-series chassis mount stroke" draggable="false" />
                                    </h1>
                                    <div className="button-holder">
                                        <a style={{marginRight: '8px'}} href="https://www.mrt.com.au/x-series-chassis-mount-canopy/" className="button"><span className="hvr-ripple-out">LEARN MORE</span></a>
                                        <a href="https://www.mrt.com.au/x-series-chassis-mount-canopy-product/" className="button"><span className="hvr-ripple-out">SHOP NOW</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ChassismountBanner;
