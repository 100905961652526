
import "../../css/landing/package-review.css";
import Explorebound from "../../media/Explorebound-logo.png";
import CTPReview from "../../media/canopy-tray-package-review.png";
import PlayIcon from "../../media/play-icon.png";
import CTPMobile from "../../media/canopy-tray-package-review-mobile.png";

const PackageReview = () => {
    return (
      <div id="section-six" className="section anchored gradient-bg-rev">
  
        <div id="section-six-anchor" className="anchor"></div>
  
        <div className="container boxed">
  
          <div className="row">
  
            <div className="col col-40">
  
              <div className="content-holder">
  
                <div className="img-holder">
                  <img src={Explorebound} alt="Explorebound Logo" />
                </div>
  
                <h2 className="title">
                  <span className="hidden">Canopy &amp; Tray Package Review</span>
                <img src={CTPReview} alt="Canopy &amp; Tray Package Review" className="desktop"  />
                  <img src={CTPMobile} alt="Canopy &amp; Tray Package Review" className="mobile" />
                </h2>
  
                <p className="desc desktop">Explore the durability and functionality of the MRT canopy and tray package through the first-hand experience of Daniel, a respected voice in the outdoor and off-roading community. In his review on the widely followed Explorebound YouTube channel, Daniel provides an honest and thorough assessment of the package's features and performance, which includes a flat-bed tray, powdercoated canopy, lift-off system, keyless entry, and more. </p>
  
                <div className="btn-holder">
                  <a href="https://www.youtube.com/watch?v=sJBYfWynvzw" target="_blank" rel="noopener noreferrer">
                    <img src={PlayIcon} alt="play icon" />
                    <span>WATCH NOW</span>
                  </a>
                </div>
  
              </div>
  
            </div>
  
            <div className="col col-60">
  
              <div className="content-holder mobile">
  
                <p className="desc">Daniel from the popular YouTube channel Explorebound gives an honest review of how his MRT canopy and tray package has fared. Daniel’s setup includes a flat-bed tray, powdercoated canopy, lift-off system, keyless entry, and more.</p>
  
              </div>
            
            </div>
  
          </div>
  
        </div>
  
      </div>
    );
  }
  
  export default PackageReview;
  