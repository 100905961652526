import '../css/seven.css';
import adventure1 from '../media/mrt-adventures-1-compressed.jpg';
import adventure2 from '../media/mrt-adventures-2.jpg';
import adventure3 from '../media/mrt-adventures-3.jpg';
import adventure4 from '../media/mrt-adventures-4.jpg';
import adventure5 from '../media/mrt-adventures-5.jpg';

const Seven = () => {
    return (
        <div id="section-seven" className="section-mrt-blocks anchored">
            <div id="section-seven-anchor" className="anchor"></div>
            <div className="container full">
                <div className="mrt-row">
                    <div className="mrt-col col-100">
                        <div className="info-holder bg-clear">
                            <h2 className="title">WATCH AN <span className="transparent">EXPLOREBOUND</span><br /> MRT <span className="transparent">ADVENTURE</span></h2>
                        </div>
                    </div>
                </div>
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={adventure1} alt="MRT Adventure: The Wrong Way at Hamersley" />
                            <a href="https://www.youtube.com/watch?v=PsMraSk5uF0" target="_blank" rel="noreferrer" content=""><i className="fa-light fa-circle-play cust-fa" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div className="mrt-col col-25">
                        <div className="mrt-row">
                            <div className="mrt-col col-100">
                                <div className="image-holder">
                                    <img src={adventure2} alt="MRT Adventure: 2 Days in Coral Bay" />
                                    <a href="https://www.youtube.com/watch?v=SDP9TXqi9R4" target="_blank" rel="noreferrer" content=""><i className="fa-light fa-circle-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="mrt-row">
                            <div className="mrt-col col-100">
                                <div className="image-holder">
                                    <img src={adventure3} alt="MRT Adventure: Mount Meharry" />
                                    <a href="https://www.youtube.com/watch?v=XxcUrqKXuMw" target="_blank" rel="noreferrer" content=""><i className="fa-light fa-circle-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mrt-col col-25">
                        <div className="mrt-row">
                            <div className="mrt-col col-100">
                                <div className="image-holder">
                                    <img src={adventure4} alt="MRT Adventure: Were in Karijini" />
                                    <a href="https://www.youtube.com/watch?v=YepSPhhxyg0" target="_blank" rel="noreferrer" content=""><i className="fa-light fa-circle-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="mrt-row">
                            <div className="mrt-col col-100">
                                <div className="image-holder">
                                    <img src={adventure5} alt="MRT Adventure: Pilbara Paradise" />
                                    <a href="https://www.youtube.com/watch?v=NweyW8ALZvM" target="_blank" rel="noreferrer" content=""><i className="fa-light fa-circle-play" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Seven;