import '../../css/accessories/AccessoriesKes.css';
import KESgif from '../../media/keyless-entry-animated.gif';

const AccessoriesKES = () => {
    return ( 
        <div id="accessories-kes" className="section-category bg"> 
            <div className="container">        
                <div className="row">
                    <div className="col-sm-6">
                        <div className="info-holder" data-aos="fade-up" data-aos-duration="500" data-aos-delay="100" data-aos-anchor-placement="top-bottom">
                            <p className="mrt-subtitle" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-anchor-placement="top-bottom">PRODUCT</p>
                            <h3 className="mrt-title" data-aos="fade-up" data-aos-duration="500" data-aos-delay="700" data-aos-anchor-placement="top-bottom">KEYLESS ENTRY SYSTEMS</h3>
                            <p className="mrt-desc" data-aos="fade-up" data-aos-duration="500" data-aos-delay="800" data-aos-anchor-placement="top-bottom">The MRT innovative keyless entry system allows you to access your heavy-duty MRT ute canopy or toolbox promptly and with ease. Our market leading keyless entry system has been thoroughly trialled and tested for quality control and longevity. Purchase your new MRT ute canopy or toolbox with a fully fitted keyless entry system or upgrade your existing MRT products with our DIY kit.</p>
                            <div className="animation-holder" data-aos="zoom-out" data-aos-duration="300" data-aos-delay="900" data-aos-anchor-placement="top-bottom">
                                <a href="https://www.mrt.com.au/accessories/keyless-entry-system/">
                                    <span className="hvr-ripple-out">VIEW PRODUCT RANGE</span>
                                </a>
                            </div>
                        </div>	
                    </div>
                    <div className="col-sm-6">
                        <div className="image-holder" data-aos="zoom-out" data-aos-duration="300" data-aos-delay="500" data-aos-anchor-placement="top-bottom">
                            <img src={KESgif} alt="MRT keyless entry animated" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default AccessoriesKES;
