import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import MRTonUte from "../../media/mrt25d-on-ute.png";
const UteToolboxesBuilttoLast = () => {
    return ( 
        <div className="section-mrt-blocks image-right-background default">
            <div id="ute-toolboxes-built-to-last" className="anchor-holder"></div>
            <div className="container fullwidth text-align-right">
                <div className="mrt-row">
                    <div className="mrt-col col-50">
                        <div className="info-holder">
                            <p className="mrt-subtitle"></p>
                            <h3 className="mrt-title">UTE TOOLBOXES BUILT TO LAST</h3>
                            <p className="mrt-desc">MRT ute toolboxes are the most durable and robust on the market. The majority of our products are made with sturdy, thick 2.5mm aluminium checker plate and fitted with strong T-locks to ensure maximum protection of your tools and equipment while onsite or unattended. Our innovative locking systems can be added to all your MRT products for ease of access which will increase your onsite safety and productivity. MRT toolboxes are fitted with weatherproof seals to protect your valuable tools from harsh environmental elements - while they are all completely seam welded and fit with full-length piano hinges for added strength and reinforcement.</p>
                        </div>
                    </div>
                    <div className="mrt-col col-50">
                        <div className="image-holder">
                            <img src={MRTonUte} alt="MRT Toolbox on Ute" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UteToolboxesBuilttoLast;
