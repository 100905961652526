import '../css/FooterSearch.css';
const FooterSearch = () => {
    return (
        <div id="section-footer-search">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div>
                            <form className="footersearch-mrt" role="search" method="get" id="searchform" action="https://www.mrt.com.au/">
                                <input className='mrt-search-footer' type="text" name="s" id="s" placeholder="What product are you looking for?" />
                                <button type="submit" className="mrt-search-footer-submit" id="searchsubmit">SEARCH MRT PRODUCT RANGE</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterSearch;