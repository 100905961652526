import React from 'react';
import '../../css/uppericonsCss.css';
import image1 from '../../media/uppericons/undertray drawer.png';
import image2 from '../../media/uppericons/generator.png';
import image3 from '../../media/uppericons/three fourth opening.png';
import image4 from '../../media/uppericons/chest style.png';
import image5 from '../../media/uppericons/crossdeck.png';
import image6 from '../../media/uppericons/gullwing.png';
import image7 from '../../media/uppericons/rectangular plain.png';
import image8 from '../../media/uppericons/drawbar.png';
import image9 from '../../media/uppericons/full opening.png';
import image10 from '../../media/uppericons/dog-boxes-new.png';
import { useState, useEffect } from 'react';

const ToolboxUppericons = ({ props }) => {
    const cleanedStr = props.replace(/<\/?p>/g, "");
    const regex = /\[([^[\]]+)\]/g;
    const result = cleanedStr.match(regex).map(match => match.slice(1, -1));
    // console.log(result);

    return (
        <div id="section-toolboxes-uppericons">
            <div className="container">
                <div className="uppericons-wrapper">

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/undertray-toolboxes/" className="uppericon">
                        <img src={image1} alt="MRT undertray drawer" />
                        <p>
                            UNDERTRAY<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/generator-toolboxes/" className="uppericon">
                        <img src={image2} alt="MRT generator" />
                        <p>
                            GENERATOR<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/3-4-opening-toolboxes/" className="uppericon">
                        <img src={image3} alt="MRT three fourth opening" />
                        <p>
                            3/4 OPENING<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/chest-style-toolboxes/" className="uppericon">
                        <img src={image4} alt="MRT chest style" />
                        <p>
                            CHEST STYLE<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/crossdeck-toolboxes/" className="uppericon">
                        <img src={image5} alt="MRT crossdeck" />
                        <p>
                            CROSSDECK<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/gullwing-toolboxes/" className="uppericon">
                        <img src={image6} alt="MRT gullwing" />
                        <p>
                            GULLWING<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/rectangular-plain-toolboxes/" className="uppericon">
                        <img src={image7} alt="MRT rectangular plain" />
                        <p>
                            RECTANGULAR<br />
                            PLAIN TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/drawbar-toolboxes/" className="uppericon">
                        <img src={image8} alt="MRT drawbar" />
                        <p>
                            DRAWBAR<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href="https://www.mrt.com.au/aluminium-toolboxes/full-opening-toolboxes/" className="uppericon">
                        <img src={image9} alt="MRT full opening" />
                        <p>
                            FULL OPENING<br />
                            TOOLBOX
                        </p>
                    </a>

                    <a href={image10} className="uppericon">
                        <img src={image10} alt="MRT dog-boxes-new" />
                        <p>
                            DOG BOXES<br /><br />
                        </p>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ToolboxUppericons;
