import React from "react";
import "../../css/landing/mrtAdventure.css";
import teleTrack from "../../media/mrtAdventures/uluru-pexels-photo-6610368-1-5.jpg";
import litchfield from "../../media/mrtAdventures/Reynolds-River-Crossing-1-1-1.jpg";
import capeTribulation from "../../media/mrtAdventures/Emmagen-Creek-Crossing-2.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const MrtAdventure = () => {
  return (
    <>
      <section id="mrt-adventure">
        <div id="section-banner-anchor" className="anchor"></div>
        <div className="container wide">
          <div className="row">
            <div className="col col-100">
              <h2 className="title">
                MRT <span className="transparent">ADVENTURES</span>
              </h2>
              <Swiper
                slidesPerView={'auto'}
                spaceBetween={10}
                breakpoints={{
                  // when window width is >= 480px
                  480: {
                    spaceBetween: 120
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 10
                  }
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <Link to="/mrt-adventures/old-tele-track/">
                    <img
                      loading="lazy"
                      src={teleTrack}
                      alt=""
                    />
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <a
                    href="https://www.mrt.com.au/mrt-adventures/litchfield-national-park/"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      src={litchfield}
                      alt=""
                    />
                  </a>{" "}
                </SwiperSlide>
                <SwiperSlide>
                  <a
                    href="https://www.mrt.com.au/mrt-adventures/daintree-forest-cape-tribulation/"
                    target="_blank"
                  >
                    <img
                      loading="lazy"
                      src={capeTribulation}
                      alt=""
                    />
                  </a>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MrtAdventure;
