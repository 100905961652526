
import Header from '../sections/header';
import useFetch from '../../useFetch';
import { Helmet } from 'react-helmet-async';
import AdventureBanner from '../sections/adventures/AdventureBanner';
import AdventureInfo from '../sections/adventures/AdventureInfo';
import AdventureDetails from '../sections/adventures/AdventureDetails';
import AdventureGears from '../sections/adventures/AdventureGears';
import AdventureGallery from '../sections/adventures/AdventureGallery';
import Seven from '../sections/seven';
import Eight from '../sections/eight';
import Nine from '../sections/nine';
import NextSteps from '../sections/nextsteps';
import Footer from '../sections/footer';
import '../css/adventures/Adventures.css';

const Adventures = () => {
	const {data, ispending, error} = useFetch('https://mrt.abovedigital.co/wp-json/wp/v2/pages/23035');
	return (
    <>
		<Header title="MRT"/>
		<div id="main" className="site-main page__top-shadow visible">
			<div id="main-content" className="main-content">
				{error && <div>{error}</div>}
				{ispending && <div className='adventure-loading'></div>}
				{data && 
					<>
						<Helmet meta={[
							{name: 'description', content: data.yoast_head_json.description},
							{name: 'robots', content: Object.keys(data.yoast_head_json.robots).map(key => `${key}: ${data.yoast_head_json.robots[key]}`).join(', ')},
							{name: 'canonical', content: data.yoast_head_json.canonical},
							{property: 'og:title', content: data.yoast_head_json.og_title},
							{property: 'og:locale', content: data.yoast_head_json.og_locale},
							{property: 'og:type', content: data.yoast_head_json.og_type},
							{property: 'og:url', content: data.yoast_head_json.og_url},
							{property: 'og:site_name', content: data.yoast_head_json.og_site_name},
							{property: 'article:publisher', content: data.yoast_head_json.article_publisher},
							{property: 'article:modified_time', content: data.yoast_head_json.article_modified_time},
							{property: 'og:image', content: data.yoast_head_json.og_image[0].url},
							{property: 'og:image:width', content: data.yoast_head_json.og_image[0].width},
							{property: 'og:image:height', content: data.yoast_head_json.og_image[0].height},
							{property: 'og:description', content: data.yoast_head_json.og_description},
							{property: 'twitter:card', content: data.yoast_head_json.twitter_card},
						]}
						title={data.yoast_head_json.title}
						/>
						<AdventureBanner props={data.acf.banner} />
						<AdventureInfo props={data.acf.info} />
						<AdventureDetails props={data.acf.details} />
						<AdventureGears props={data.acf.recommended_gear} />
						<AdventureGallery props={data.acf.gallery} />
					</>
				}
				<Seven />
				<Eight />
				<Nine />
				<NextSteps/>
				<Footer/>
 			</div>
		</div>
    </>
	);
}

export default Adventures;
