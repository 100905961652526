import React from "react";
import "../css/PackageChoice.css";
// import "../css/swiperBundle.css";
import initiatorTitle from "../media/PackageImages/Initiator.png";
import explorerTitle from "../media/PackageImages/Explorer.png";
import tradesmanTitle from "../media/PackageImages/Tradesman.png";
import companionTitle from "../media/PackageImages/Companion.png";
import greenArrow from "../media/PackageImages/greenarrow.png";
import initiatorMobile from "../media/PackageImages/initiatorMobile.png";
import explorerMobile from "../media/PackageImages/explorerMobile.png";
import tradesmanMobile from "../media/PackageImages/tradesmanMobile.png";
import companionMobile from "../media/PackageImages/companionMobile.png";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const PackageChoice = () => {

	return (
		<>
			<div id="section-package-choice" className="section desktop anchored">
				<div id="section-package-choice-anchor" className="anchor"></div>

				<div className="container full">
					<div className="row">
						<div className="col col-25">
							<div className="initiator-bg bg-holder">
								<div className="content-holder">
									<h3>
										<img src={initiatorTitle} alt="intiator package" />
									</h3>
									<div className="button-text-holder">
										<span>save up to $1,550</span>
										<a href="https://www.mrt.com.au/packages/initiator-package/">
											<span>
												Learn more <img src={greenArrow} alt="green arrow" />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col col-25">
							<div className="explorer-bg bg-holder">
								<div className="content-holder">
									<h3>
										<img src={explorerTitle} alt="explorer package" />
									</h3>
									<div className="button-text-holder">
										<span>save up to $2,650</span>
										<a href="https://www.mrt.com.au/packages/explorer-package/">
											<span>
												Learn more <img src={greenArrow} alt="green arrow" />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col col-25">
							<div className="tradesman-bg bg-holder">
								<div className="content-holder">
									<h3>
										<img src={tradesmanTitle} alt="tradesman package" />
									</h3>
									<div className="button-text-holder">
										<span>save up to $1,900</span>
										<a href="https://www.mrt.com.au/packages/tradesman-package/">
											<span>
												Learn more <img src={greenArrow} alt="green arrow" />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col col-25">
							<div className="companion-bg bg-holder">
								<div className="content-holder">
									<h3>
										<img src={companionTitle} alt="companion package" />
									</h3>
									<div className="button-text-holder">
										<span>save up to $1,800</span>
										<a href="https://www.mrt.com.au/packages/companion-package/">
											<span>
												Learn more <img src={greenArrow} alt="green arrow" />
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="section-package-choice" className="section mobile anchored">
				<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
					<SwiperSlide>
						<div className="initiator-bg bg-holder">
							<div className="content-holder">
								<h3>
									<img src={initiatorMobile} alt="Initiator package" />
								</h3>
								<div className="button-text-holder">
									<span>save up to $1,550</span>
									<a href="https://www.mrt.com.au/packages/initiator-package/">
										<span>Learn more <img src={greenArrow} alt="green arrow" /></span>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="explorer-bg bg-holder">
							<div className="content-holder">
								<h3>
									<img src={explorerMobile} alt="explorer package" />
								</h3>
								<div className="button-text-holder">
									<span>save up to $2,650</span>
									<a href="https://www.mrt.com.au/packages/explorer-package/">
										<span>Learn more <img src={greenArrow} alt="green arrow" /></span>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="tradesman-bg bg-holder">
							<div className="content-holder">
								<h3>
									<img src={tradesmanMobile} alt="tradesman package" />
								</h3>
								<div className="button-text-holder">
									<span>save up to $1,900</span>
									<a href="https://www.mrt.com.au/packages/tradesman-package/">
										<span>Learn more <img src={greenArrow} alt="green arrow" /></span>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="companion-bg bg-holder">
							<div className="content-holder">
								<h3>
									<img src={companionMobile} alt="companion package" />
								</h3>
								<div className="button-text-holder">
									<span>save up to $1,800</span>
									<a href="https://www.mrt.com.au/packages/companion-package/">
										<span>Learn more <img src={greenArrow} alt="green arrow" /></span>
									</a>
								</div>
							</div>
						</div>
					</SwiperSlide>
				</Swiper>
			</div>

		</>
	);
};

export default PackageChoice;