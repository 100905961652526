import "../../css/aluminium-toolboxes/ToolBoxesRightImage.css";
import UndetrayTB from "../../media/undertray-toolboxes-on-ute.png";

const ReliableTimeSaving = () => {
    return ( 
      <div className="section-mrt-blocks image-right-background default">
        <div id="reliable-&-time-saving" className="anchor-holder"></div>
        <div className="container fullwidth text-align-right">
          <div className="mrt-row">
            <div className="mrt-col col-50">
              <div className="info-holder">
                <p className="mrt-subtitle"></p>
                <h3 className="mrt-title">RELIABLE &amp; TIME SAVING</h3>
                <p className="mrt-desc">MRT toolboxes are water/dustproof and quick and easy to access (due to our innovative locking/unlocking system) which is ideal when you're short on time. Increase your onsite productivity and general convenience with one of our premium designed MRT toolboxes.</p>
              </div>
            </div>
            <div className="mrt-col col-50">
              <div className="image-holder">
                <img src={UndetrayTB} alt="MRT Toolboxes"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default ReliableTimeSaving;
  